import firebase from '@/services/firebase';
import { GlobalStyles } from '@/styles/globalStyles';
import { LoaderContext, ThemeContext, UserContext } from '@/context';
import { lazy, useContext, useEffect, useRef, useState } from 'react';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar';
import { ThemeProvider } from 'styled-components';
import FallbackLoader from './components/common/FallbackLoader';
import Header from './components/Header';
import JobsPushLayout from './components/JobsPushLayout';
import routing from './userRouting';
import getUserFromStorage from './utils/getUserFromStorage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Home = lazy(() => import('@/pages/Home'));
const UnauthorisedChannelsAndBundles = lazy(
  () => import('@/pages/UnauthorisedChannelsAndBundles'),
);
const Auth = lazy(() => import('@/pages/Auth'));
const TermsOfService = lazy(() => import('@/pages/Policy/TermsOfService'));
const PrivacyPolicy = lazy(() => import('@/pages/Policy/PrivacyPolicy'));
const CookiePolicy = lazy(() => import('@/pages/Policy/CookiePolicy'));
const DiscoverProducts = lazy(
  () => import('@/pages/FindDevelopersPages/FindDevelopersDiscover'),
);
const ChannelsProducts = lazy(
  () => import('@/pages/FindDevelopersPages/FindDevelopersChannels'),
);
const BundlesProducts = lazy(
  () => import('@/pages/FindDevelopersPages/FindDevelopersBundles'),
);

export type User = firebase.UserInfo;

export type AuthPageType =
  | 'signup'
  | 'login'
  | 'forgotpwd'
  | 'linksent'
  | 'createpwd'
  | 'getaccess'
  | 'accesslinksent';

export const authPages: Array<AuthPageType> = [
  'signup',
  'login',
  'forgotpwd',
  'linksent',
  'createpwd',
  // 'getaccess',
  // 'accesslinksent',
];

const App = () => {
  const [theme, setTheme] = useState(useContext(ThemeContext).theme);
  const [user, setUser] = useState<User | undefined>(getUserFromStorage());
  const loader = useRef<LoadingBarRef>(null);
  const { pathname } = useLocation();

  const startLoader = () => {
    loader?.current?.continuousStart(0, 1000);
  };

  const stopLoader = () => {
    loader?.current?.complete();
  };

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('idToken', (await user?.getIdToken()) || '');
      setUser(user ? user : undefined);
    });

    (function (d, t) {
      var BASE_URL = 'https://app.chatwoot.com';
      var g = d.createElement(t),
        s = d.getElementsByTagName(t)[0];
      // @ts-ignore
      g.src = BASE_URL + '/packs/js/sdk.js';
      // @ts-ignore
      g.defer = true;
      // @ts-ignore
      g.async = true;
      // @ts-ignore
      s.parentNode.insertBefore(g, s);
      g.onload = function () {
        // @ts-ignore
        window.chatwootSDK.run({
          websiteToken: 'JgvZK2h96asSygpg9FEgrYq1',
          baseUrl: BASE_URL,
        });
      };
    })(document, 'script');
  }, []);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, [pathname]);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <ToastContainer />
        <LoadingBar color={theme.blue} ref={loader} />
        <LoaderContext.Provider value={{ startLoader, stopLoader }}>
          <UserContext.Provider value={{ user }}>
            <Routes>
              <Route
                path="/"
                element={
                  <>
                    <Header />
                    <Outlet />
                  </>
                }
              >
                <Route
                  path="/"
                  element={
                    <FallbackLoader>
                      <Home />
                    </FallbackLoader>
                  }
                />
                {!user &&
                  authPages.map((page) => (
                    <Route
                      key={page}
                      path={`/${page}`}
                      element={
                        <FallbackLoader>
                          <Auth pageType={page} />
                        </FallbackLoader>
                      }
                    />
                  ))}
                <Route
                  path="/unauthorised-channels"
                  element={
                    <FallbackLoader>
                      <UnauthorisedChannelsAndBundles type="channels" />
                    </FallbackLoader>
                  }
                />
                <Route
                  path="/unauthorised-bundles"
                  element={
                    <FallbackLoader>
                      <UnauthorisedChannelsAndBundles type="bundles" />
                    </FallbackLoader>
                  }
                />
                <Route
                  path="/terms-of-service"
                  element={
                    <FallbackLoader>
                      <TermsOfService />
                    </FallbackLoader>
                  }
                />
                <Route
                  path="/privacy-policy"
                  element={
                    <FallbackLoader>
                      <PrivacyPolicy />
                    </FallbackLoader>
                  }
                />
                <Route
                  path="/find-developers-in-telegram"
                  element={
                    <FallbackLoader>
                      <ChannelsProducts type="telegram" />
                    </FallbackLoader>
                  }
                />
                <Route
                  path="/find-developers-in-job-board"
                  element={
                    <FallbackLoader>
                      <ChannelsProducts type="jobBoard" />
                    </FallbackLoader>
                  }
                />
                <Route
                  path="/find-developers-in-reddit"
                  element={
                    <FallbackLoader>
                      <ChannelsProducts type="reddit" />
                    </FallbackLoader>
                  }
                />
                <Route
                  path="/find-developers-bundles"
                  element={
                    <FallbackLoader>
                      <BundlesProducts />
                    </FallbackLoader>
                  }
                />
                <Route
                  path="/find-developers-discover"
                  element={
                    <FallbackLoader>
                      <DiscoverProducts />
                    </FallbackLoader>
                  }
                />
                <Route
                  path="/cookie-policy"
                  element={
                    <FallbackLoader>
                      <CookiePolicy />
                    </FallbackLoader>
                  }
                />
              </Route>
              {user && (
                <Route path="/" element={<JobsPushLayout />}>
                  {routing.map((el) => (
                    <Route
                      key={el.path}
                      path={el.path}
                      element={<FallbackLoader>{el.element}</FallbackLoader>}
                    />
                  ))}
                </Route>
              )}
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </UserContext.Provider>
        </LoaderContext.Provider>
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default App;
