import styled from 'styled-components';
import { ThemeProps } from '@/styles/globalStyles';

interface IProps {
  variant: 'primary' | 'videoplayer';
}
export const ModalBlock = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgb(0, 0, 0, 0.8);
  z-index: 100;
  margin: 0;
  padding: 0;
`;

export const ModalContainer = styled.div`
  position: relative;
  overflow: hidden;
  max-width: 70rem;
  max-height: 70rem;
  width: auto;
  box-sizing: border-box;
  margin: 0 1rem;
  background-color: ${(props: IProps & ThemeProps) =>
    props.variant === 'videoplayer' ? props.theme.black : props.theme.white};
  border-radius: 10px;
  @media (max-width: 48rem) {
    min-width: 96%;
    margin: 0 1rem;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
  align-items: center;
  padding: 1rem 0;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  @media (max-width: 48rem) {
    padding: 1rem 0;
  }
`;

export const IconBlock = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
`;

export const ModalHeader = styled.h2`
  margin: 1rem 0;
  font-weight: 700;
  font-size: 1.5rem;
  box-sizing: border-box;
  line-height: 1.6875rem;
  color: ${(props: ThemeProps) => props.theme.black};
  @media (max-width: 48rem) {
    font-size: 1.2rem;
    line-height: 1.5rem;
    margin-left: 1rem;
    margin-bottom: 0;
  }
`;
