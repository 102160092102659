import routing from '@/userRouting';
import { memo, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useIsMobile } from '@/hooks/useIsMobile';
import Button from '@/components/common/Button';
import BurgerMenu from '@/components/common/icons/BurgerMenu';
import BurgerMenuClose from '@/components/common/icons/BurgerMenuClose';
import Logo from '@/components/common/icons/Logo';
import User from './components/User';
import * as styles from './index.styles';
import CartDropdown from '@/components/JobsPushLayout/components/HeaderAuth/components/CartDropdown';

const HeaderAuth = () => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { jobId = '' } = useParams();
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      document.body.style.overflowY = 'auto';
    } else {
      document.body.style.overflowY = 'hidden';
    }
  }, [isOpen]);

  const open = () => {
    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
  };

  return !isMobile ? (
    <styles.Content>
      <styles.LogoBlock>
        <Link to="/dashboard">
          <Logo />
        </Link>
      </styles.LogoBlock>
      <styles.ButtonBlock>
        <Button
          size="small"
          variant="contained"
          onClick={() => navigate('/jobs/create')}
        >
          Create job
        </Button>
      </styles.ButtonBlock>
      <styles.CartBlock>
        <CartDropdown />
      </styles.CartBlock>
      <User />
    </styles.Content>
  ) : (
    <>
      <styles.Background isOpen={isOpen} />
      <styles.Content>
        <styles.MobileHeader>
          <Link to="/dashboard">
            <Logo />
          </Link>
          {isOpen ? (
            <BurgerMenuClose onClick={close} />
          ) : (
            <BurgerMenu onClick={open} />
          )}
          {isOpen && (
            <styles.LinksWrapper>
              <styles.Navigation>
                {routing.map(
                  ({ path, name, icon }) =>
                    icon && (
                      <Link to={path} key={path} onClick={close}>
                        <Button icon={icon}>{name}</Button>
                      </Link>
                    ),
                )}
              </styles.Navigation>
              <styles.Separator />
              <User />
            </styles.LinksWrapper>
          )}
        </styles.MobileHeader>
      </styles.Content>
      <styles.SecondContent>
        <styles.Wrapper>
          <styles.NameOfPageBlock>
            {
              routing.find(
                (el) => el.path.replace(':jobId', jobId) === pathname,
              )?.name
            }
          </styles.NameOfPageBlock>
          <Button
            size="small"
            variant="contained"
            onClick={() => navigate('/jobs/create')}
          >
            Create job
          </Button>
        </styles.Wrapper>
        <styles.CartBlock>
          <CartDropdown />
        </styles.CartBlock>
      </styles.SecondContent>
    </>
  );
};

export default memo(HeaderAuth);
