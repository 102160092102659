import styled from 'styled-components';
import { ThemeProps } from '@/styles/globalStyles';
import { StylesProps } from '@/utils/stylesProps';

type Props = ThemeProps & StylesProps;

interface IProps {
  isEmpty?: boolean;
}

export const SelectBlock = styled.span``;

export const Select = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 1.5rem;
  line-height: 1.6875rem;
  border: 1px solid ${(props: ThemeProps) => props.theme.stroke};
  border-radius: 0.25rem;
  box-sizing: border-box;
  display: inline-block;
  cursor: pointer;
  background-color: ${(props: ThemeProps) => props.theme.white};
  & svg {
    margin-left: 0.4rem;
    width: 1rem;
    height: 0.5rem;
    transform: ${(props: Props & IProps) =>
      props.isOpen ? 'none' : 'rotate(180deg)'};
  }
`;

export const SelectButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & button {
    width: calc(100% - 2rem);
    & span {
      display: block;
      text-align: left;
      overflow: hidden;
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    &:hover {
      transform: scale(1);
    }
  }
  @media (max-width: 48rem) {
    padding-top: 0;
  }
`;

export const ContentWrapper = styled.div`
  position: absolute;
  top: 3.125rem;
  left: 0;
  z-index: 7;
  display: ${(props: Props & IProps) => (props.isOpen ? `block` : `none`)};
  max-height: 15rem;
  border-radius: 0.25rem;
  display: ${(props: Props & IProps) => (props.isOpen ? `block` : `none`)};
  margin-top: 0.4rem;
  box-shadow: rgb(0 0 0 / 5%) 0px 1px 1px, rgb(0 0 0 / 5%) 0px 2px 2px,
    rgb(0 0 0 / 5%) 0px 5px 5px, rgb(0 0 0 / 5%) 0px 10px 10px,
    rgb(0 0 0 / 5%) 0px 0px 8px;
  border: 0.0625rem solid;
  box-sizing: border-box;
  border-color: ${(props: ThemeProps) => props.theme.stroke};
  background-color: ${(props: ThemeProps) => props.theme.white};
  width: 100%;
`;

export const Content = styled.ul`
  max-height: 11rem;
  padding: 0.2rem 0;
  background-color: ${(props: ThemeProps) => props.theme.white};
  width: 100%;
  box-sizing: border-box;
  border-radius: 0.25rem;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: ${(props: IProps & ThemeProps) => (props.isEmpty ? `0` : `0.4rem`)};
    height: 0.5rem;
    margin-right: ${(props: IProps & ThemeProps) =>
      props.isEmpty ? `0` : `0.3rem`};
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 0.1875rem;
    background-color: ${(props: ThemeProps) => props.theme.stroke};
    margin-left: 0.3rem;
  }
  & li {
    margin: 0;
    margin-left: ${(props: IProps) => (props.isEmpty ? `0` : `0.4rem`)};
    cursor: pointer;
    color: ${(props: ThemeProps) => props.theme.black};
    padding: 0.75rem 1rem;
    text-decoration: none;
    display: block;
    &:hover {
      background-color: ${(props: ThemeProps) => props.theme.stroke}60;
      border-radius: 0.25rem;
    }
  }
`;

export const ElemetnContent = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  img {
    max-width: 1.5rem;
    max-height: 1.5rem;
    width: 100%;
    height: 100%;
  }
`;

export const ElementInfo = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 1rem;
  font-weight: 500;
  width: calc(100% - 1.5rem - 1rem);
  box-sizing: border-box;
  justify-content: center;
  & div {
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    text-overflow: ellipsis;
  }
  & span {
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    text-overflow: ellipsis;
    display: block;
    font-size: 0.85rem;
    font-weight: 500;
    color: ${(props: ThemeProps) => props.theme.grey};
  }
`;

export const HeaderContent = styled.div`
  border-radius: 0.25rem;
  box-sizing: border-box;
  border-radius: 0.25rem 0.25rem 0 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 0.1rem solid ${(props: ThemeProps) => props.theme.stroke};
  padding: 0.75rem 1rem;
`;

export const HeaderTitle = styled.div`
  color: ${(props: ThemeProps) => props.theme.black};
  font-weight: 600;
`;

export const IconBlock = styled.div`
  cursor: pointer;
  & svg {
    max-width: 0.9rem;
    max-height: 0.9rem;
    width: 100%;
    height: 100%;
    color: ${(props: ThemeProps) => props.theme.black};
  }
  & svg path[stroke] {
    stroke: ${(props: ThemeProps) => props.theme.black};
  }
`;

export const EmptyBlock = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 1.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  @media (max-width: 48rem) {
    font-size: 1rem;
  }
`;

export const FooterContent = styled.div`
  border-radius: 0.25rem;
  box-sizing: border-box;
  border-radius: 0 0 0.25rem 0.25rem;
  width: 100%;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: flex-start;
  background-color: ${(props: ThemeProps) => props.theme.stroke};
  padding: 0.75rem 1rem;
`;

export const Plus = styled.div`
  font-size: 1.2rem;
  font-weight: 400;
  margin-right: 0.5rem;
`;
