import { ReactElement } from 'react';
import routing from '@/userRouting';
import { CustomButton } from './index.styles';
import {useLocation } from 'react-router-dom';

interface Props {
  variant?: 'text' | 'contained' | 'outlined' | 'tab';
  children?: ReactElement | null | string;
  icon?: ReactElement | null;
  secondIcon?: ReactElement | null;
  size?: 'medium' | 'small';
  weight?: 'bold' | 'normal';
  onClick?: (e: React.SyntheticEvent) => void;
  disabled?: boolean;
}

const Button = ({
  variant = 'text',
  children = null,
  icon = null,
  secondIcon = null,
  size = 'medium',
  weight = 'bold',
  onClick,
  disabled = false,
}: Props) => {
  const { pathname } = useLocation();
  return (
    <CustomButton
      variant={variant}
      size={size}
      weight={weight}
      onClick={onClick}
      aria-label="button"
      disabled={disabled}
      routing={routing.find(el => el.path === pathname)?.name}
    >
      {icon}
      {children && <span>{children}</span>}
      {secondIcon}
    </CustomButton>
  );
};

export default Button;
