import routing from '@/userRouting';
import { CartContextProvider } from '@/context';
import { memo } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import HeaderAuth from './components/HeaderAuth';
import Sidebar from './components/Sidebar';
import * as styles from './index.styles';

export const JobsPushLayout = () => {
  const { pathname } = useLocation();
  const { jobId = '' } = useParams();

  return (
    <CartContextProvider>
      <styles.Layout>
        <HeaderAuth />

        <styles.ContentWrapper>
          <Sidebar />
          <styles.Wrapper>
            <styles.Content>
              <styles.NameOfPageBlock>
              {routing.find(el => el.path.replace(':jobId', jobId) === pathname)?.name}
              </styles.NameOfPageBlock>
                <Outlet />
            </styles.Content>
          </styles.Wrapper>
        </styles.ContentWrapper>
        
      </styles.Layout>
    </CartContextProvider>
  );
};

export default memo(JobsPushLayout);
