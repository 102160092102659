import { memo } from 'react';

interface Props {
  onClick?: (e: React.SyntheticEvent) => void;
}

const Logo = ({ onClick }: Props) => (
  <svg
    width="126"
    height="24"
    viewBox="0 0 126 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
  >
    <path
      d="M9.9169 24C10.301 24 10.7627 23.8523 11.3019 23.5568L19.4903 18.8476C19.8227 18.6556 20.0886 18.4524 20.2881 18.2382C20.4875 18.024 20.6279 17.7765 20.7091 17.4958C20.7978 17.2151 20.8421 16.8717 20.8421 16.4654V7.00277C20.8421 6.34534 20.7239 5.84672 20.4875 5.50693C20.2585 5.16713 19.9372 4.99723 19.5235 4.99723C19.1394 4.99723 18.6888 5.14497 18.1717 5.44044L9.9723 10.1717C9.6325 10.3712 9.35919 10.578 9.15235 10.7922C8.95291 11.0065 8.80886 11.2539 8.72022 11.5346C8.63897 11.8153 8.59834 12.1588 8.59834 12.5651V22.0166C8.59834 22.6519 8.70914 23.1394 8.93075 23.4792C9.15974 23.8264 9.48846 24 9.9169 24ZM5.4072 21.4294C5.6362 21.4294 5.8578 21.3592 6.07202 21.2188V10.0942C6.07202 9.92428 6.0831 9.79132 6.10526 9.69529C6.13481 9.59926 6.18652 9.51801 6.26039 9.45152C6.33426 9.37765 6.44137 9.30009 6.58172 9.21884L16.2216 3.65651C16.2364 3.27978 16.1367 2.9843 15.9224 2.77008C15.7082 2.54848 15.4349 2.43767 15.1025 2.43767C14.7996 2.43767 14.4783 2.53001 14.1385 2.71468L5.41828 7.73407C5.05632 7.9409 4.7904 8.12558 4.6205 8.28809C4.4506 8.44321 4.3398 8.63527 4.28809 8.86427C4.23638 9.08587 4.21053 9.4072 4.21053 9.82825V19.7784C4.21053 20.2733 4.32133 20.6722 4.54294 20.9751C4.77193 21.2779 5.06002 21.4294 5.4072 21.4294ZM1.19668 19.0028C1.41828 19.0028 1.63989 18.9326 1.8615 18.7922V7.65651C1.8615 7.50139 1.87258 7.37211 1.89474 7.2687C1.92428 7.16528 1.97599 7.08033 2.04986 7.01385C2.12373 6.93998 2.23084 6.86242 2.37119 6.78116L12.0222 1.21884C12.0222 0.842105 11.9151 0.54663 11.7008 0.33241C11.494 0.110803 11.2281 0 10.903 0C10.578 0 10.253 0.099723 9.92798 0.299169L1.21884 5.30748C0.856879 5.51431 0.587258 5.69529 0.409972 5.85042C0.240074 6.00554 0.129271 6.1976 0.0775623 6.42659C0.0258541 6.65559 0 6.98061 0 7.40166V17.3407C0 17.8356 0.110803 18.2382 0.33241 18.5485C0.554017 18.8513 0.842105 19.0028 1.19668 19.0028Z"
      fill="#2685F0"
    />
    <path
      d="M31.5869 19.9338C31.0873 19.9485 30.6979 19.8161 30.4188 19.5366C30.1396 19.257 30 18.8966 30 18.4552C30 18.1168 30.1029 17.8225 30.3086 17.5724C30.4996 17.3076 30.7861 17.1678 31.1681 17.1531L31.8073 17.109C32.395 17.0648 32.8358 16.8956 33.1296 16.6014C33.4235 16.3071 33.5704 15.8584 33.5704 15.2552V5.74345C33.5704 5.18437 33.7174 4.7577 34.0112 4.46345C34.3051 4.15448 34.7238 4 35.2675 4C35.8258 4 36.2519 4.15448 36.5458 4.46345C36.8396 4.7577 36.9866 5.18437 36.9866 5.74345V15.211C36.9866 16.1821 36.8103 17.006 36.4576 17.6828C36.1197 18.3448 35.6054 18.8598 34.9148 19.2276C34.2243 19.5954 33.3721 19.8161 32.3582 19.8897L31.5869 19.9338Z"
      fill="#2685F0"
    />
    <path
      d="M45.0106 20C43.8498 20 42.836 19.772 41.9691 19.3159C41.1169 18.8598 40.4557 18.2051 39.9855 17.3517C39.5153 16.4984 39.2803 15.4906 39.2803 14.3283C39.2803 13.4602 39.4125 12.6805 39.677 11.989C39.9414 11.2975 40.3235 10.709 40.823 10.2234C41.3373 9.72322 41.9471 9.34805 42.6523 9.09793C43.3576 8.8331 44.1437 8.70069 45.0106 8.70069C46.1713 8.70069 47.1778 8.92874 48.03 9.38483C48.8969 9.84092 49.5654 10.4883 50.0356 11.3269C50.5205 12.1655 50.7629 13.166 50.7629 14.3283C50.7629 15.211 50.6233 15.9982 50.3442 16.6897C50.0797 17.3811 49.6977 17.977 49.1981 18.4772C48.6985 18.9775 48.0888 19.36 47.3688 19.6248C46.6635 19.8749 45.8775 20 45.0106 20ZM45.0106 17.5062C45.4954 17.5062 45.9142 17.3885 46.2668 17.1531C46.6195 16.9177 46.8986 16.572 47.1043 16.1159C47.31 15.6451 47.4129 15.0492 47.4129 14.3283C47.4129 13.2543 47.1925 12.4671 46.7517 11.9669C46.3109 11.452 45.7305 11.1945 45.0106 11.1945C44.5404 11.1945 44.1216 11.3048 43.7543 11.5255C43.4017 11.7462 43.1225 12.092 42.9168 12.5628C42.7258 13.0189 42.6303 13.6074 42.6303 14.3283C42.6303 15.4023 42.8507 16.2041 43.2915 16.7338C43.7323 17.2487 44.3053 17.5062 45.0106 17.5062Z"
      fill="#2685F0"
    />
    <path
      d="M59.4657 20C58.5988 20 57.8274 19.7867 57.1515 19.36C56.4903 18.9186 56.0495 18.3375 55.8291 17.6166L56.0716 16.8883V18.2566C56.0716 18.8009 55.932 19.2202 55.6528 19.5145C55.3736 19.8087 54.9696 19.9559 54.4406 19.9559C53.9117 19.9559 53.5003 19.8087 53.2064 19.5145C52.9272 19.2202 52.7876 18.8009 52.7876 18.2566V5.69931C52.7876 5.14023 52.9272 4.72092 53.2064 4.44138C53.5003 4.14713 53.919 4 54.4627 4C54.9916 4 55.3957 4.14713 55.6748 4.44138C55.9687 4.72092 56.1156 5.14023 56.1156 5.69931V10.9297H55.8512C56.0863 10.2676 56.5344 9.73058 57.1956 9.31862C57.8568 8.90667 58.6135 8.70069 59.4657 8.70069C60.4207 8.70069 61.2509 8.92874 61.9562 9.38483C62.6614 9.84092 63.2124 10.4883 63.6091 11.3269C64.0058 12.1655 64.2042 13.166 64.2042 14.3283C64.2042 15.4906 64.0058 16.4984 63.6091 17.3517C63.2124 18.2051 62.6541 18.8598 61.9341 19.3159C61.2288 19.772 60.406 20 59.4657 20ZM58.4739 17.5062C58.9441 17.5062 59.3555 17.3885 59.7081 17.1531C60.0754 16.9177 60.3546 16.572 60.5456 16.1159C60.7513 15.6451 60.8542 15.0492 60.8542 14.3283C60.8542 13.2543 60.6338 12.4671 60.193 11.9669C59.7522 11.452 59.1791 11.1945 58.4739 11.1945C58.0037 11.1945 57.5849 11.3048 57.2176 11.5255C56.865 11.7462 56.5858 12.092 56.3801 12.5628C56.1744 13.0189 56.0716 13.6074 56.0716 14.3283C56.0716 15.4023 56.292 16.2041 56.7327 16.7338C57.1735 17.2487 57.7539 17.5062 58.4739 17.5062Z"
      fill="#2685F0"
    />
    <path
      d="M70.383 20C69.7365 20 69.068 19.9411 68.3774 19.8234C67.7016 19.7057 67.0844 19.5071 66.5261 19.2276C66.2322 19.0805 66.0192 18.8966 65.887 18.6759C65.7694 18.4405 65.718 18.2051 65.7327 17.9697C65.7474 17.7343 65.8208 17.5209 65.9531 17.3297C66.1 17.1384 66.2837 17.0133 66.5041 16.9545C66.7392 16.8809 66.9963 16.903 67.2755 17.0207C67.8779 17.2708 68.4289 17.4474 68.9284 17.5503C69.428 17.6533 69.9202 17.7048 70.4051 17.7048C71.0369 17.7048 71.4924 17.6092 71.7716 17.4179C72.0654 17.212 72.2123 16.9471 72.2123 16.6234C72.2123 16.3292 72.1168 16.1085 71.9258 15.9614C71.7348 15.8143 71.4557 15.7113 71.0883 15.6524L68.7742 15.2331C67.8632 15.0713 67.1579 14.7402 66.6583 14.24C66.1735 13.7251 65.931 13.0703 65.931 12.2759C65.931 11.5402 66.1367 10.9076 66.5481 10.3779C66.9596 9.83356 67.5252 9.42161 68.2452 9.14207C68.9799 8.84782 69.81 8.70069 70.7357 8.70069C71.3969 8.70069 72.0066 8.75954 72.565 8.87724C73.1233 8.99494 73.6596 9.18621 74.1739 9.45104C74.4384 9.58345 74.622 9.76 74.7249 9.98069C74.8424 10.2014 74.8792 10.4294 74.8351 10.6648C74.8057 10.9002 74.7175 11.1136 74.5706 11.3048C74.4237 11.4961 74.2327 11.6212 73.9976 11.68C73.7772 11.7389 73.52 11.7094 73.2262 11.5917C72.756 11.371 72.3225 11.2166 71.9258 11.1283C71.5291 11.0253 71.1471 10.9738 70.7798 10.9738C70.1186 10.9738 69.6411 11.0841 69.3472 11.3048C69.0533 11.5108 68.9064 11.7756 68.9064 12.0993C68.9064 12.3494 68.9872 12.5628 69.1488 12.7393C69.3251 12.9012 69.5896 13.0115 69.9423 13.0703L72.2564 13.4897C73.2115 13.6515 73.9388 13.9752 74.4384 14.4607C74.9379 14.9315 75.1877 15.5789 75.1877 16.4028C75.1877 17.5209 74.7469 18.4037 73.8653 19.051C72.9984 19.6837 71.8377 20 70.383 20Z"
      fill="#2685F0"
    />
    <path
      d="M79.0929 19.9559C78.5345 19.9559 78.1084 19.8014 77.8146 19.4924C77.5207 19.1834 77.3738 18.7494 77.3738 18.1903V5.94207C77.3738 5.36828 77.5207 4.93425 77.8146 4.64C78.1231 4.34575 78.5566 4.19862 79.1149 4.19862H84.4705C86.2043 4.19862 87.5414 4.64 88.4818 5.52276C89.4221 6.39081 89.8923 7.6046 89.8923 9.16414C89.8923 10.7237 89.4221 11.9448 88.4818 12.8276C87.5414 13.7103 86.2043 14.1517 84.4705 14.1517H80.7899V18.1903C80.7899 18.7494 80.643 19.1834 80.3491 19.4924C80.0699 19.8014 79.6512 19.9559 79.0929 19.9559ZM80.7899 11.5034H83.8975C84.7791 11.5034 85.4476 11.3048 85.9031 10.9076C86.3733 10.5103 86.6084 9.9292 86.6084 9.16414C86.6084 8.39908 86.3733 7.82529 85.9031 7.44276C85.4476 7.06023 84.7791 6.86897 83.8975 6.86897H80.7899V11.5034Z"
      fill="#2685F0"
    />
    <path
      d="M95.5184 20C94.6075 20 93.8508 19.8308 93.2484 19.4924C92.6459 19.154 92.1978 18.6464 91.9039 17.9697C91.6248 17.2782 91.4852 16.4248 91.4852 15.4097V10.4221C91.4852 9.86299 91.6248 9.44368 91.9039 9.16414C92.1978 8.8846 92.6092 8.74483 93.1382 8.74483C93.6671 8.74483 94.0785 8.8846 94.3724 9.16414C94.6662 9.44368 94.8132 9.86299 94.8132 10.4221V15.4979C94.8132 16.1453 94.9454 16.6308 95.2099 16.9545C95.4744 17.2634 95.8931 17.4179 96.4661 17.4179C97.0979 17.4179 97.6122 17.1972 98.0089 16.7559C98.4203 16.2998 98.626 15.7039 98.626 14.9683V10.4221C98.626 9.86299 98.7656 9.44368 99.0448 9.16414C99.3386 8.8846 99.7501 8.74483 100.279 8.74483C100.823 8.74483 101.234 8.8846 101.513 9.16414C101.807 9.44368 101.954 9.86299 101.954 10.4221V18.2566C101.954 19.3894 101.418 19.9559 100.345 19.9559C99.8162 19.9559 99.4121 19.8087 99.1329 19.5145C98.8538 19.2202 98.7142 18.8009 98.7142 18.2566V16.7779L99.0448 17.6386C98.7215 18.389 98.2587 18.9701 97.6563 19.3821C97.0539 19.794 96.3412 20 95.5184 20Z"
      fill="#2685F0"
    />
    <path
      d="M108.673 20C108.026 20 107.358 19.9411 106.667 19.8234C105.991 19.7057 105.374 19.5071 104.816 19.2276C104.522 19.0805 104.309 18.8966 104.177 18.6759C104.059 18.4405 104.008 18.2051 104.022 17.9697C104.037 17.7343 104.11 17.5209 104.243 17.3297C104.39 17.1384 104.573 17.0133 104.794 16.9545C105.029 16.8809 105.286 16.903 105.565 17.0207C106.167 17.2708 106.718 17.4474 107.218 17.5503C107.718 17.6533 108.21 17.7048 108.695 17.7048C109.327 17.7048 109.782 17.6092 110.061 17.4179C110.355 17.212 110.502 16.9471 110.502 16.6234C110.502 16.3292 110.406 16.1085 110.215 15.9614C110.024 15.8143 109.745 15.7113 109.378 15.6524L107.064 15.2331C106.153 15.0713 105.448 14.7402 104.948 14.24C104.463 13.7251 104.221 13.0703 104.221 12.2759C104.221 11.5402 104.426 10.9076 104.838 10.3779C105.249 9.83356 105.815 9.42161 106.535 9.14207C107.269 8.84782 108.1 8.70069 109.025 8.70069C109.687 8.70069 110.296 8.75954 110.855 8.87724C111.413 8.99494 111.949 9.18621 112.464 9.45104C112.728 9.58345 112.912 9.76 113.014 9.98069C113.132 10.2014 113.169 10.4294 113.125 10.6648C113.095 10.9002 113.007 11.1136 112.86 11.3048C112.713 11.4961 112.522 11.6212 112.287 11.68C112.067 11.7389 111.81 11.7094 111.516 11.5917C111.046 11.371 110.612 11.2166 110.215 11.1283C109.819 11.0253 109.437 10.9738 109.069 10.9738C108.408 10.9738 107.931 11.0841 107.637 11.3048C107.343 11.5108 107.196 11.7756 107.196 12.0993C107.196 12.3494 107.277 12.5628 107.438 12.7393C107.615 12.9012 107.879 13.0115 108.232 13.0703L110.546 13.4897C111.501 13.6515 112.228 13.9752 112.728 14.4607C113.228 14.9315 113.477 15.5789 113.477 16.4028C113.477 17.5209 113.037 18.4037 112.155 19.051C111.288 19.6837 110.127 20 108.673 20Z"
      fill="#2685F0"
    />
    <path
      d="M117.052 19.9559C116.508 19.9559 116.089 19.8087 115.796 19.5145C115.516 19.2202 115.377 18.8009 115.377 18.2566V5.69931C115.377 5.14023 115.516 4.72092 115.796 4.44138C116.089 4.14713 116.508 4 117.052 4C117.581 4 117.985 4.14713 118.264 4.44138C118.558 4.72092 118.705 5.14023 118.705 5.69931V10.9517H118.396C118.734 10.2308 119.234 9.67908 119.895 9.29655C120.571 8.89931 121.335 8.70069 122.187 8.70069C123.054 8.70069 123.767 8.86989 124.325 9.20828C124.883 9.53195 125.302 10.0322 125.581 10.709C125.86 11.371 126 12.217 126 13.2469V18.2566C126 18.8009 125.853 19.2202 125.559 19.5145C125.28 19.8087 124.869 19.9559 124.325 19.9559C123.796 19.9559 123.385 19.8087 123.091 19.5145C122.812 19.2202 122.672 18.8009 122.672 18.2566V13.4014C122.672 12.651 122.532 12.114 122.253 11.7903C121.989 11.452 121.577 11.2828 121.019 11.2828C120.314 11.2828 119.748 11.5034 119.322 11.9448C118.911 12.3862 118.705 12.9747 118.705 13.7103V18.2566C118.705 19.3894 118.154 19.9559 117.052 19.9559Z"
      fill="#2685F0"
    />
  </svg>
);

export default memo(Logo);
