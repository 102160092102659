import { useState, useEffect } from 'react';

export function useIsMobile() {
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    setIsMobile(document.body.offsetWidth < 768);
    window.addEventListener('resize', handleResize);
  }, []);

  return isMobile;
}
