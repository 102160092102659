import Button from '@/components/common/Button';
import { openChat } from '@/utils/helpers';
import { useNavigate } from 'react-router-dom';
import * as styles from './index.styles';

interface IProps {
  errorMessage: string;
  closeModal: () => void;
}
const SubscriptionErrorModal = ({ closeModal, errorMessage }: IProps) => {
  const navigate = useNavigate();
  return (
    <styles.ModalContent>
      <div>{errorMessage}</div>
      <styles.ModalButtonsWrapper>
        <Button
          variant="outlined"
          onClick={() => {
            closeModal();
            openChat();
          }}
        >
          Contact us
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            closeModal();
            navigate('/settings');
          }}
        >
          Upgrade subscription
        </Button>
      </styles.ModalButtonsWrapper>
    </styles.ModalContent>
  );
};

export default SubscriptionErrorModal;
