import { memo } from 'react';

interface Props {
  onClick: (e: React.SyntheticEvent) => void;
}

const BurgerMenuClose = ({ onClick }: Props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
  >
    <path
      d="M3.25 3.25L21.0522 20.8022"
      stroke="#292D32"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.0522 3.25L3.25 20.8022"
      stroke="#292D32"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default memo(BurgerMenuClose);
