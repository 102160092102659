import { memo } from 'react';

interface Props {
  onClick?: (e: React.SyntheticEvent) => void;
}

const Cart = ({ onClick }: Props) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
  >
    <g clipPath="url(#clip0_550_6977)">
      <path
        d="M6.59961 21C7.70418 21 8.59961 20.1046 8.59961 19C8.59961 17.8954 7.70418 17 6.59961 17C5.49504 17 4.59961 17.8954 4.59961 19C4.59961 20.1046 5.49504 21 6.59961 21Z"
        stroke="#2685F0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5996 21C18.7042 21 19.5996 20.1046 19.5996 19C19.5996 17.8954 18.7042 17 17.5996 17C16.495 17 15.5996 17.8954 15.5996 19C15.5996 20.1046 16.495 21 17.5996 21Z"
        stroke="#2685F0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5996 17H6.59961V3H4.59961"
        stroke="#2685F0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.59961 5L20.5996 6L19.5996 13H6.59961"
        stroke="#2685F0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_550_6977">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0.599609)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default memo(Cart);
