import { useEffect, useMemo, useState } from 'react';
import { useIsMobile } from '@/hooks/useIsMobile';
import { HashLink } from 'react-router-hash-link';
import BurgerMenu from '../common/icons/BurgerMenu';
import BurgerMenuClose from '../common/icons/BurgerMenuClose';
import Logo from '../common/icons/Logo';
import {
  Container,
  DesktopNavWrapper,
  MobileNavMenu,
  MobileNavWrapper,
} from './index.styles';
import NavLinks from './NavLinks';
import { useLocation } from 'react-router-dom';
import { isFindPage } from '@/utils/helpers';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useIsMobile();
  const { pathname } = useLocation();

  const isFindDevelopersPage = useMemo(() => {
    return isFindPage(pathname);
  }, [pathname]);

  const open = () => {
    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (!isOpen) {
      document.body.style.overflowY = 'auto';
    } else {
      document.body.style.overflowY = 'hidden';
    }
  }, [isOpen]);

  return (
    <Container isFindDevelopersPage={isFindDevelopersPage}>
      <HashLink to="/#" onClick={close}>
        <Logo />
      </HashLink>
      {!isMobile ? (
        <DesktopNavWrapper>
          <NavLinks />
        </DesktopNavWrapper>
      ) : (
        <MobileNavWrapper>
          {!isOpen ? (
            <BurgerMenu onClick={open} />
          ) : (
            <BurgerMenuClose onClick={close} />
          )}
          <MobileNavMenu isOpen={isOpen}>
            <NavLinks closeMobileNav={close} isMobile={isMobile} />
          </MobileNavMenu>
        </MobileNavWrapper>
      )}
    </Container>
  );
};

export default Header;
