import { IOnboarding, ISearchParams } from '@/utils/types';

export const showCurrencySymbol = (currency: string) => {
  switch (currency) {
    case 'EUR':
      return '€';
    case 'PLN':
      return 'zł';
    case 'GBP':
      return '£';
    default:
      return '$';
  }
};

export const showPeriod = (period: string) => {
  switch (period) {
    case 'HOURLY':
      return 'hour';
    case 'WEEKLY':
      return 'week';
    case 'YEARLY':
      return 'year';
    default:
      return 'month';
  }
};

export const displayDate = (str: string) => {
  return new Date(str).toDateString();
};

export const openChat = (): void => {
  document
    .getElementById('woot-widget-bubble-icon')
    // @ts-ignore
    ?.parentNode?.click();
};

export const getNumberOfDays = (start: Date, end: Date = new Date()) => {
  return Math.round((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24));
};

export const showPrice = (price: number, currency: string) => {
  const p = price !== 0 ? `${showCurrencySymbol(currency)}${price}` : 'Free';
  return p;
};

export const capitalizeFirstLetter = (str: string) =>
  str[0].toUpperCase() + str.slice(1).toLocaleLowerCase();

export const getShortNumberOfaudienc = (num: number) => {
  if (num > 1000000) {
    return `${Math.round(num / 1000000)}M`;
  } else if (num > 1000) {
    return `${Math.round(num / 1000)}K`;
  } else {
    return `${num}`;
  }
};

export const readSearchParams = (
  searchParams: URLSearchParams,
): ISearchParams => {
  const type = searchParams.get('type') || undefined;
  const name = searchParams.get('name') || undefined;
  const tags = searchParams.get('tags') || undefined;
  const geo = searchParams.get('geo') || undefined;
  const minPrice = searchParams.get('minPrice')
    ? Number(searchParams.get('minPrice'))
    : undefined;
  const maxPrice = searchParams.get('maxPrice')
    ? Number(searchParams.get('maxPrice'))
    : undefined;
  return {
    type,
    name,
    tags,
    geo,
    maxPrice,
    minPrice,
  };
};

export const getParamsString = (searchParams: URLSearchParams) => {
  const { name, tags, minPrice, maxPrice, geo } =
    readSearchParams(searchParams);
  return `${
    (name ? `&name=${name}` : ``) +
    (tags ? `&tags=${tags}` : ``) +
    (geo ? `&geo=${geo}` : ``) +
    (minPrice ? `&minPrice=${minPrice}` : ``) +
    (maxPrice ? `&maxPrice=${maxPrice}` : ``)
  }`;
};

export const isFindPage = (path: string) =>
  [
    '/find-developers-in-reddit',
    '/find-developers-in-telegram',
    '/find-developers-in-job-board',
    '/find-developers-bundles',
    '/find-developers-discover',
    '/unauthorised-channels',
    '/unauthorised-bundles',
  ].includes(path);

export const exponentialBackoff = async (
  callback: () => Promise<IOnboarding[] | null>,
) => {
  const backoffTimers: number[] = [0, 250, 500, 1000, 1500, 3000, 5000];
  for (const timeout of backoffTimers) {
    await new Promise((resolve) => {
      setTimeout(resolve, timeout);
    });

    const attemptData = await callback();
    if (attemptData) return attemptData;
  }

  return null;
};
