import styled from 'styled-components';
import { ThemeProps } from '@/styles/globalStyles';
import { StylesProps } from '@/utils/stylesProps';
import { Variant } from '.';

type Props = ThemeProps & StylesProps;

interface IProps {
  variant?: Variant;
  padding?: string;
  borderRadius?: string;
  backgroundSelect?: string;
}

export const SelectBlock = styled.span``;

export const Select = styled.div`
  position: relative;
  width: ${(props: IProps) =>
    props.variant === 'compensationType' ? `6.875rem` : `100%`};
  height: 100%;
  padding: ${(props: IProps) => (props.padding ? props.padding : `0 1.5rem`)};
  line-height: 1.6875rem;
  border: ${(props: ThemeProps & IProps) =>
    props.variant === 'compensationType' || props.variant === 'currency'
      ? `none`
      : `0.0625rem solid ${props.theme.stroke}`};
  border-radius: ${(props: IProps & ThemeProps) =>
    props.borderRadius ? props.borderRadius : `0.25rem`};
  box-sizing: border-box;
  display: inline-block;
  cursor: pointer;
  background-color: ${(props: IProps & ThemeProps) =>
    props.backgroundSelect ? props.backgroundSelect : props.theme.white};
  & svg {
    margin-left: 0.4rem;
    width: ${(props: ThemeProps & IProps) =>
      props.variant === 'currency' ? `0.7rem` : `1rem`};
    height: 0.5rem;
    transform: ${(props: Props & IProps) =>
      props.isOpen ? 'none' : 'rotate(180deg)'};
  }
`;

export const Content = styled.ul`
  max-height: 15rem;
  border-radius: ${(props: IProps & ThemeProps) =>
    props.borderRadius ? props.borderRadius : `0.25rem`};
  display: ${(props: Props & IProps) => (props.isOpen ? `block` : `none`)};
  position: relative;
  margin-top: 0.2rem;
  box-shadow: rgb(0 0 0 / 5%) 0px 1px 1px, rgb(0 0 0 / 5%) 0px 2px 2px,
    rgb(0 0 0 / 5%) 0px 5px 5px, rgb(0 0 0 / 5%) 0px 10px 10px,
    rgb(0 0 0 / 5%) 0px 0px 8px;
  border: 0.0625rem solid;
  box-sizing: border-box;
  border-color: ${(props: ThemeProps) => props.theme.stroke};
  background-color: ${(props: ThemeProps) => props.theme.white};
  width: 100%;
  }
`;

export const Element = styled.li`
  cursor: pointer;
  color: ${(props: IProps & ThemeProps) => props.theme.black};
  padding: 0.75rem 1rem;
  margin: 0;
  text-decoration: none;
  display: block;
  &:hover {
    background-color: #f1f1f1;
    border-radius: ${(props: IProps) =>
      props.borderRadius ? props.borderRadius : `0.25rem`};
  }
`;

export const SelectButtonWrapper = styled.div`
  display: flex;
  padding-top: ${(props: IProps) =>
    props.variant === 'currency' || props.variant === 'compensationType'
      ? `0.2rem`
      : `0`};
  justify-content: ${(props: IProps) =>
    props.variant === 'currency'
      ? `center`
      : props.variant === 'compensationType'
      ? `flex-start`
      : `space-between`};
  align-items: center;
  & button {
    &:hover {
      transform: scale(1);
    }
  }
  @media (max-width: 48rem) {
    padding-top: 0;
  }
`;

export const ArrowButtonWrapper = styled.div`
  transform: rotate(180deg);
  margin-left: 0.2rem;
`;
