import Button from '@/components/common/Button';
import Cart from '@/components/common/icons/Cart';
import Close from '@/components/common/icons/Close';
import Trash from '@/components/common/icons/Trash';
import { showPrice } from '@/utils/helpers';
import useOnClickOutside from '@/hooks/useOnClickOutside';
import { getAllJobs, postOrder } from '@/services/api';
import { CartContext, LoaderContext } from '@/context';
import { ICartItem, ICreateOrderError, IJob, IProductId } from '@/utils/types';
import { memo, useContext, useEffect, useMemo, useRef, useState } from 'react';
import * as styles from './index.styles';
import { useParams } from 'react-router-dom';
import JobsSelect from '@/components/JobsPushLayout/components/HeaderAuth/components/JobsSelect';
import Tooltip from '@/components/Tooltip';
import Modal from '@/components/common/Modal';
import SubscriptionErrorModal from './components/SubscriptionErrorModal';

function mapOrders(ordersArray: ICartItem[]): IProductId[] {
  return ordersArray.map(({ productId }) => ({ productId }));
}
const CartDropdown = () => {
  const { items: cartItems, removeItem } = useContext(CartContext);
  const { jobId: paramsJobId = '' } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [subscriptionError, setSubscriptionError] = useState<string>('');
  const [allJobs, setAllJobs] = useState<IJob[]>([]);
  const [selectedJobId, setSelectedJobId] = useState(paramsJobId);
  const { startLoader, stopLoader } = useContext(LoaderContext);
  const wrapperRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  const getJobs = async () => {
    try {
      startLoader();
      const response = await getAllJobs();
      if (response.status === 200) {
        setAllJobs(response.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      stopLoader();
    }
  };

  useEffect(() => {
    getJobs();
  }, []);

  useEffect(() => {
    if (isOpen) {
      getJobs();
    }
  }, [isOpen]);

  useEffect(() => {
    setSelectedJobId(paramsJobId);
  }, [paramsJobId]);

  const selectedJob = useMemo(() => {
    return allJobs.find((item: IJob) => item.id === selectedJobId);
  }, [selectedJobId, allJobs]);

  useOnClickOutside(wrapperRef, isOpen, () => {
    setIsOpen(false);
  });

  const disabledButton = useMemo(() => {
    return !cartItems.length || !selectedJob;
  }, [cartItems, selectedJob]);

  const makeOrder = async () => {
    if (!cartItems.length || !selectedJobId) return;
    try {
      startLoader();
      const response = await postOrder({
        jobId: selectedJobId,
        products: mapOrders(cartItems),
      });

      if (response.status === 200) {
        window.open(`${response.data.url}`, '_blank');
      }
    } catch (error) {
      const { errorCode } = error as ICreateOrderError;

      if (
        errorCode === 'subscription.missing' ||
        errorCode === 'publications.limit'
      ) {
        setSubscriptionError(errorCode);
        setIsOpen(false);
        setIsOpenModal(true);
      } else {
        console.log(error);
      }
    } finally {
      stopLoader();
    }
  };

  const mapErrorMessage = (str: string) => {
    switch (str) {
      case 'subscription.missing':
        return 'Your subscription has been expired, please upgrade subscription or contact us for any questions.';
      case 'publications.limit':
        return 'You requested more publications that are available in your current plan, please upgrade subscription or contact us for any questions.';
      default:
        return '';
    }
  };

  const TotalPrice = useMemo(() => {
    return cartItems
      .map((item) => {
        return item?.price || 0;
      })
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  }, [cartItems]);

  const triggerClose = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <styles.DropdownContainer ref={wrapperRef}>
      {isOpenModal && (
        <Modal variant="primary" onClose={() => setIsOpenModal(false)}>
          <SubscriptionErrorModal
            closeModal={() => setIsOpenModal(false)}
            errorMessage={mapErrorMessage(subscriptionError)}
          />
        </Modal>
      )}
      <styles.DropdownButton isOpen={isOpen} onClick={triggerClose}>
        <Cart />
        Your Cart <span>({cartItems.length})</span>
      </styles.DropdownButton>
      <styles.DropdownMenu isOpen={isOpen}>
        <styles.Header>
          <styles.Title>
            Your cart <span>({cartItems.length})</span>
          </styles.Title>
          <styles.CloseButton onClick={triggerClose}>
            <Close />
          </styles.CloseButton>
        </styles.Header>
        <styles.Separator />
        {selectedJobId ? (
          <styles.JobName>
            Selected job: <span>{selectedJob?.title}</span>
          </styles.JobName>
        ) : (
          <styles.JobName>Job is not selected!</styles.JobName>
        )}
        {!paramsJobId && (
          <styles.SelectWrapper>
            <JobsSelect
              value={selectedJobId}
              setCloseCart={() => setIsOpen(false)}
              options={allJobs.map((job) => ({
                company: job.company,
                companyLogo: job.companyLogo,
                title: job.title,
                value: job.id,
              }))}
              onChange={setSelectedJobId}
            />
          </styles.SelectWrapper>
        )}
        <styles.Separator />
        <styles.CartItemsBlock>
          {!cartItems.length ? (
            <styles.CartIsEmpty>Cart is empty!</styles.CartIsEmpty>
          ) : (
            cartItems.map((cartItem, index) => (
              <li key={JSON.stringify(cartItem)}>
                <div>
                  <styles.InfoBlock>
                    <img
                      loading="lazy"
                      width="100%"
                      height="100%"
                      src={
                        cartItem?.logoUrl
                          ? cartItem.logoUrl
                          : `/images/default_job_placeholder.png`
                      }
                      alt={cartItem.channelName + `icon`}
                    />
                    <styles.Info>
                      <styles.Name>{cartItem.channelName}</styles.Name>
                      <styles.NumberOfDays>
                        single post <styles.RoundSeparator />
                        {cartItem.postDaysLength || ''}
                      </styles.NumberOfDays>
                    </styles.Info>
                  </styles.InfoBlock>
                </div>
                <styles.ProductPriceBlock>
                  {cartItem.price && (
                    <styles.ProductPrice>
                      {showPrice(cartItem.price, cartItem.currency)}
                    </styles.ProductPrice>
                  )}
                  <styles.TrashIconWrapper onClick={() => removeItem(index)}>
                    <Trash />
                  </styles.TrashIconWrapper>
                </styles.ProductPriceBlock>
              </li>
            ))
          )}
        </styles.CartItemsBlock>
        <styles.Separator />
        <styles.PriceBlock>
          <div>Total Price</div>
          <span>{TotalPrice}$</span>
        </styles.PriceBlock>
        <styles.ButtonWrapper>
          {disabledButton ? (
            <Tooltip
              positionTop="-4.5rem"
              mobilePositionTop="-3.5rem"
              text="
            
              Please select a job and channels before checkout"
            >
              <Button
                variant="contained"
                disabled={disabledButton}
                onClick={makeOrder}
              >
                Proceed to Checkout
              </Button>
            </Tooltip>
          ) : (
            <Button variant="contained" onClick={makeOrder}>
              Proceed to Checkout
            </Button>
          )}
        </styles.ButtonWrapper>
      </styles.DropdownMenu>
    </styles.DropdownContainer>
  );
};

export default memo(CartDropdown);
