import styled from 'styled-components';

export const ModalContent = styled.div`
  padding: 1.5rem;
  font-size: 1.2rem;
  max-width: 40rem;
  line-height: 1.5rem;
  display: flex;
  flex-direction: column;
  @media (max-width: 30rem) {
    font-size: 1.2rem;
  }
`;

export const ModalButtonsWrapper = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  @media (max-width: 30rem) {
    gap: 1rem;
    flex-direction: column;
    & button {
      width: 100%;
    }
  }
`;
