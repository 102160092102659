import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { decode } from 'base-64';
import { getAnalytics } from 'firebase/analytics';

const config = process.env.REACT_APP_FIREBASE_CONFIG_BASE64;

const firebaseConfig = config && JSON.parse(decode(config));

export const app = firebase.initializeApp(firebaseConfig);

getAnalytics(app);

export const auth = firebase.auth();

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: 'select_account' });

export const signInWithGoogle = () => auth.signInWithPopup(provider);

export default firebase;
