import { User } from '@/App';

const getUserFromStorage = () => {
  const user = localStorage.getItem('user');
  if (!user) return undefined;
  const parsed = JSON.parse(user);
  if (typeof parsed === 'object' && parsed !== null && parsed.uid) {
    return parsed as User;
  }
};

export default getUserFromStorage;
