import { ReactElement, useRef } from 'react';
import Close from '../icons/Close';
import * as styles from './index.styles';
import useOnClickOutside from '@/hooks/useOnClickOutside';

interface Props {
  variant: 'primary' | 'videoplayer';
  children: ReactElement | null | string;
  title?: string;
  onClose: () => void;
}

const Modal = ({ onClose, children, title, variant }: Props) => {
  const wrapperRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  useOnClickOutside(wrapperRef, true, () => {
    onClose();
  });

  return (
    <styles.ModalBlock>
      <styles.ModalContainer variant={variant} ref={wrapperRef}>
        <styles.IconBlock onClick={onClose}>
          <Close />
        </styles.IconBlock>
        <styles.ModalContent>
          {variant === 'primary' && (
            <styles.ModalHeader>{title}</styles.ModalHeader>
          )}
          {children}
        </styles.ModalContent>
      </styles.ModalContainer>
    </styles.ModalBlock>
  );
};

export default Modal;
