import { ThemeProps } from '@/styles/globalStyles';
import styled from 'styled-components';

interface IProps {
  isOpen: boolean;
}

interface IBackground {
  isFindDevelopersPage?: boolean;
}

export const Container = styled.header<IBackground>`
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  background-color: ${(props) =>
    props.isFindDevelopersPage ? props.theme.white : props.theme.background};
  color: ${(props: ThemeProps) => props.theme.deepDarkBlue};
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  box-sizing: border-box;
  z-index: 1;
`;

export const Span = styled.div`
  color: ${(props: ThemeProps) => props.theme.blue};
`;

export const DesktopNavWrapper = styled.div`
  margin-left: 3.25rem;
  width: 100%;
  & nav {
    display: flex;
    justify-content: space-between;
    & > div {
      display: flex;
      align-items: center;
      & > * {
        margin: 0 0.75rem;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  @media (max-width: 62rem) {
    margin-left: 0.75rem;
    & nav > div > * {
      margin: 0 0.65rem;
      &:last-child {
        margin-right: 0;
      }
    }
  }
`;

export const MobileNavWrapper = styled.div`
  & > svg {
    cursor: pointer;
    &:hover {
      transform: scale(1.05);
    }
  }
`;

interface Props {
  isOpen: boolean;
}

export const MobileNavMenu = styled.div`
  position: fixed;
  top: 4.6rem;
  right: 0;
  width: ${(props: Props) => (props.isOpen ? '100%' : 0)};
  padding: ${(props: Props) => (props.isOpen ? '0 2rem' : 0)};
  transition: width 100ms;
  height: calc(100vh - 4.6rem);
  box-sizing: border-box;
  overflow: auto;
  background-color: ${(props: ThemeProps) => props.theme.background};
  * {
    font-weight: 600 !important;
    font-size: 1rem !important;
    line-height: 1.688rem !important;
  }
  & nav {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    & * {
      max-width: 100%;
    }
    & > div {
      &:first-child {
        width: 100%;
        padding: 4rem 0;
        border-bottom: 0.063rem solid
          ${(props: ThemeProps) => props.theme.stroke};
        @media (max-width: 48rem) {
          padding: 2rem 0;
        }
      }
      &:last-child {
        margin-top: 4rem;
        & button {
          width: 21rem;
          max-width: 100%;
          padding-top: 1rem;
          padding-bottom: 1rem;
          max-height: none;
        }
      }
    }
  }
  @media (max-width: 37.5rem) {
    padding: ${(props: Props) => (props.isOpen ? '0 1.25rem 1rem' : 0)};
  }
`;

export const ToolsButton = styled.button`
  position: relative;
  padding: 0.75rem 0;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  color: ${(props: ThemeProps) => props.theme.deepDarkBlue};
  &:hover {
    transform: scale(1.05);
  }
`;

export const Tools = styled.div`
  position: absolute;
  top: calc(100% - 0.2rem);
  right: 0;
  width: 9.438rem;
  padding: 0.5rem 0;
  border-radius: 0.75rem;
  background-color: ${(props: ThemeProps) => props.theme.white};
  box-shadow: -1.65999px 2.48998px 8.29993px rgba(55, 58, 79, 0.08);
  & a {
    padding: 0.5rem 1rem;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.313rem;
    display: block;
    width: 100%;
    box-sizing: border-box;
    text-align: left;
    color: ${(props: ThemeProps) => props.theme.deepDarkBlue};
    &:hover {
      background-color: ${(props: ThemeProps) => props.theme.background};
      & span {
        display: inline-block;
        transform: scale(1.05);
      }
    }
  }
`;

interface ArrowProps {
  isOpen: boolean;
}

export const ArrowWrapper = styled.i`
  display: flex;
  margin-left: 0.6rem;
  & svg {
    transform: ${(props: ArrowProps) =>
      props.isOpen ? 'rotate(180deg)' : 'none'};
  }
`;

export const DiscordButton = styled.a`
  & svg path {
    fill: ${(props: ThemeProps) => props.theme.deepDarkBlue};
    @media (max-width: 48rem) {
      fill: ${(props: ThemeProps) => props.theme.blue};
    }
  }
`;

export const AccountButton = styled.button`
  position: relative;
  padding: 0.25rem 0;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  &:hover {
    transform: scale(1.05);
  }
  & img {
    border-radius: 50%;
  }
`;

export const AccountActions = styled.div`
  position: absolute;
  top: calc(100% - 0.2rem);
  right: 0;
  width: 9.25rem;
  padding: 0.5rem 0;
  border-radius: 0.75rem;
  background-color: ${(props: ThemeProps) => props.theme.white};
  box-shadow: -1.65999px 2.48998px 8.29993px rgba(55, 58, 79, 0.08);
  & a {
    padding: 0.5rem 1.625rem;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.375rem;
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    text-align: left;
    color: ${(props: ThemeProps) => props.theme.deepDarkBlue};
    & svg {
      margin-right: 0.75rem;
    }
    &:hover {
      transform: scale(1.05);
    }
    &:last-child {
      color: ${(props: ThemeProps) => props.theme.blue};
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  & button {
    max-height: 3rem;
    height: 100%;
    &:first-child {
      margin-right: 1.5rem;
    }
  }
`;

export const MobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
`;

export const Dropdown = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
  & svg {
    margin-left: 0.4rem;
    width: 1rem;
    height: 0.5rem;
    transform: ${(props: Props) => (props.isOpen ? 'none' : 'rotate(180deg)')};
  }
`;

export const Content = styled.div`
  border-radius: 0.5rem;
  display: ${(props: IProps) => (props.isOpen ? `block` : `none`)};
  position: absolute;
  top: 2.5rem;
  padding: 0;
  background-color: ${(props: ThemeProps) => props.theme.white};
  min-width: 19.375rem;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  a {
    color: ${(props: ThemeProps) => props.theme.black};
    padding: 1rem;
    text-decoration: none;
    display: block;
    &:hover {
      background-color: #f1f1f1;
      border-radius: 0.5rem;
    }
  }
`;
