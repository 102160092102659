import { ReactElement, useState } from 'react';
import InfoCircle from '../common/icons/InfoCircle';
import * as styles from './index.styles';

interface IProps {
  text: string;
  children?: ReactElement | null | string;
  backgroundColor?: string;
  textColor?: string;
  positionTop?: string;
  mobilePositionTop?: string;
  paddingButton?: string;
  width?: number;
  withoutArrow?: boolean;
}
const Tooltip = ({
  mobilePositionTop,
  paddingButton,
  positionTop,
  textColor,
  backgroundColor,
  text,
  children,
  width,
  withoutArrow,
}: IProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const triggerClose = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <styles.TooltipContainer>
      <styles.TooltipContent
        mobilePositionTop={mobilePositionTop}
        positionTop={positionTop}
        textColor={textColor}
        backgroundColor={backgroundColor}
        isOpen={isOpen}
        width={width}
      >
        {text}
        {!withoutArrow && (
          <styles.TooltipContentTriangle>
            <styles.BottomArrow />
          </styles.TooltipContentTriangle>
        )}
      </styles.TooltipContent>
      <styles.TooltipButton
        paddingButton={paddingButton}
        onClick={triggerClose}
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
      >
        {children ? children : <InfoCircle />}
      </styles.TooltipButton>
    </styles.TooltipContainer>
  );
};

export default Tooltip;
