import { User as IUser } from '@/App';
import LogOut from '@/components/common/icons/LogOut';
import { auth } from '@/services/firebase';
import { LoaderContext } from '@/context';
import { memo, useContext, useEffect, useState } from 'react';
import * as styles from './index.styles';

const User = () => {
  const [user, setUser] = useState<IUser>();
  const { startLoader, stopLoader } = useContext(LoaderContext);

  const logOut = () => {
    startLoader();
    auth.signOut().finally(() => stopLoader());
  };

  useEffect(() => {
    const userString = localStorage.getItem('user');
    if (userString) {
      setUser(JSON.parse(userString));
    }
  }, []);

  return (
    <styles.AccountButton>
      <img
        src={user?.photoURL ? user?.photoURL : '/images/user.webp'}
        alt=""
        width="40px"
      />
      <span>{user?.displayName || ''}</span>
      <LogOut onClick={logOut} />
    </styles.AccountButton>
  );
};

export default memo(User);
