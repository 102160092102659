import { memo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import routing from '@/userRouting';
import Button from '@/components/common/Button';
import * as styles from './index.styles';

const Sidebar = () => {
  const location = useLocation();
  const isActive = (path: string) => location.pathname.startsWith(path);

  return (
    <styles.Content>
      <styles.Navigation>
        {routing.map(
          ({ path, name, icon }) =>
            icon && (
              <Link to={path} key={path}>
                <Button variant={isActive(path) ? 'tab' : 'text'} icon={icon} />
                <styles.HoverBlock>
                  <styles.TabName>
                    <styles.Triangle />
                    {name}
                  </styles.TabName>
                </styles.HoverBlock>
              </Link>
            ),
        )}
      </styles.Navigation>
    </styles.Content>
  );
};

export default memo(Sidebar);
