export const COMPANY = 'COMPANY';
export const JOB = 'JOB';
export const PREVIEW = 'PREVIEW';

export const compensationTypes = [
  { title: 'per hour', value: 'HOURLY' },
  { title: 'per week', value: 'WEEKLY' },
  { title: 'per month', value: 'MONTHLY' },
  { title: 'per year', value: 'YEARLY' },
];

export const currencyOptions = [
  { title: 'USD', value: 'USD' },
  { title: 'EUR', value: 'EUR' },
  { title: 'PLN', value: 'PLN' },
  { title: 'GBP', value: 'GBP' },
];

export const typeOfIntegration = {
  recrutee: 'RECRUTEE',
};

export const tooltips = {
  tags: '"Tags" are keywords or phrases that are associated with a job posting and are used to categorize and organize the posting for easier search and discovery. In Telegram and Reddit they would be included as a #tags to your job post.',
  telegramHandle:
    'A "Telegram handle" is a username or account link on the Telegram. Some platforms that host job postings may require job post to include a Telegram handle. This may be used as a way for the candidate to apply for a job.',
  applyUrl:
    'An "applyUrl" is a web address or link that provides a way for job seekers to submit their application for the job. It may be a link to an online application form or to an email address where the job seeker can send their resume and other required materials.',
};

export const findDevelopersLinks = [
  {
    url: '/find-developers-in-telegram',
    text: 'header.findDevelopers.channels.telegram.dropdownTitle',
  },
  {
    url: '/find-developers-in-job-board',
    text: 'header.findDevelopers.channels.jobBoard.dropdownTitle',
  },
  {
    url: '/find-developers-in-reddit',
    text: 'header.findDevelopers.channels.reddit.dropdownTitle',
  },
  {
    url: '/find-developers-bundles',
    text: 'header.findDevelopers.bundles.dropdownTitle',
  },
  {
    url: '/find-developers-discover',
    text: 'header.findDevelopers.discover.dropdownTitle',
  },
];
