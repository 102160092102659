import {
  IChannelCreationPayload,
  IIntegration,
  IJob,
  IOnboarding,
  IOrder,
  ISearchParams,
} from '@/utils/types';
import axios from 'axios';
import { auth } from './firebase';
const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_HOST + '/jobspush-service/api/v1',
  headers: {
    'Content-Type': 'application/json',
  },
});
httpClient.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('idToken');
    if (accessToken) {
      // @ts-ignore
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
httpClient.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (err.response) {
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          const access_token = await auth.currentUser?.getIdToken(true);
          if (access_token) {
            localStorage.setItem('idToken', access_token);
            httpClient.defaults.headers.common[
              'Authorization'
            ] = `Bearer ${access_token}`;
            return httpClient(originalConfig);
          } else {
            throw new Error('Refresh error');
          }
        } catch (_error: any) {
          if (_error.response && _error.response.data) {
            return Promise.reject(_error.response.data);
          }
          return Promise.reject(_error);
        }
      }
      if (err.response.data) {
        return Promise.reject(err.response.data);
      }
      if (err.response.status === 404) {
        window.location.href = '/404';
      }
      if (err.response.status > 499) {
        window.location.href = '/500';
      }
    }
    return Promise.reject(err);
  },
);

export const createJob = async (data: IJob) => {
  try {
    return httpClient.post('/jobs', data);
  } catch (error) {
    console.log('Error happened while creating job: ', error);
    throw error;
  }
};

export const requestForChannelCreation = async (
  data: IChannelCreationPayload,
) => {
  try {
    return httpClient.post(`/channels/new`, data);
  } catch (error) {
    console.log('Error happened while request for channel creation: ', error);
    throw error;
  }
};

export const getStepsForStart = async () => {
  try {
    const res = await httpClient.get('/onboarding');
    return res.status === 200 ? (res.data as IOnboarding[]) : null;
  } catch (error) {
    console.log('Error happened while getting onboarding: ', error);
    return null;
  }
};

export const getAllJobs = async () => {
  try {
    return httpClient.get('/jobs/user');
  } catch (error) {
    console.log('Error happened while getting jobs: ', error);
    throw error;
  }
};

export const editJob = async (jobId: string, data: IJob) => {
  try {
    return httpClient.put(`/jobs/${jobId}`, data);
  } catch (error) {
    console.log(' Error happened while editing the job: ', error);
    throw error;
  }
};

export const makeCopyOfJob = async (jobId: string) => {
  try {
    return httpClient.post(`/jobs/${jobId}/copy`);
  } catch (error) {
    console.log(' Error happened while copying the job: ', error);
    throw error;
  }
};

export const getSubscriptions = async () => {
  try {
    return httpClient.get('/subscriptions/user');
  } catch (error) {
    console.log('Error happened while getting subscriptions: ', error);
    throw error;
  }
};

export const getAllTechnology = async () => {
  try {
    return httpClient.get('/tags');
  } catch (error) {
    console.log('Error happened while getting tags: ', error);
    throw error;
  }
};

export const getAllGeoTags = async () => {
  try {
    return httpClient.get('/geotags');
  } catch (error) {
    console.log('Error happened while getting tags: ', error);
    throw error;
  }
};

export const getOneJob = async (jobId: string) => {
  try {
    return httpClient.get(`/jobs/${jobId}`);
  } catch (error) {
    console.log('Error happened while getting jobs: ', error);
    throw error;
  }
};

export const deleteJob = async (jobId: string) => {
  try {
    return httpClient.delete(`/jobs/${jobId}`);
  } catch (error) {
    console.log('Error happened while delitinging jobs: ', error);
    throw error;
  }
};

export const getAllChannels = async (params: ISearchParams) => {
  try {
    return httpClient.get('/channels', { params });
  } catch (error) {
    console.log('Error happened while getting channels: ', error);
    throw error;
  }
};

export const postOrder = async (data: IOrder) => {
  try {
    return httpClient.post('/orders/checkout', data);
  } catch (error) {
    console.log('Error happened while creating order: ', error);
    throw error;
  }
};

export const editSubscriptionDetails = async () => {
  try {
    return httpClient.post('/customer-portal-session');
  } catch (error) {
    console.log('Error happened while editing subscription: ', error);
    throw error;
  }
};
export const getAllBundles = async (params: ISearchParams) => {
  try {
    return httpClient.get('/bundles', { params });
  } catch (error) {
    console.log('Error happened while getting bundles: ', error);
    throw error;
  }
};

export const addIntegration = async (data: IIntegration ) => {
  try {
    return httpClient.post(`/integrations`, data);
  } catch (error) {
    console.log('Error happened while adding integrations: ', error);
    throw error;
  }
};

export const getAllIntegrations = async () => {
  try {
    return httpClient.get('integrations/user');
  } catch (error) {
    console.log('Error happened while getting integrations: ', error);
    throw error;
  }
};


export const deleteIntegration = async (integrationId: string) => {
  try {
    return httpClient.delete(`/integrations/${integrationId}`);
  } catch (error) {
    console.log('Error happened while delitinging integration: ', error);
    throw error;
  }
};
 
export const uploadLogo = async (logo: Blob): Promise<string> => {
  try {
    const formData = new FormData();
    formData.append('resource', logo);
    const response = await httpClient.post('/logo', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (response.status !== 201) {
      throw new Error('Error while uploading image, ' + response?.statusText);
    }

    const { url = null } = response?.data;

    if (!url) {
      throw new Error('Something is wrong with response, no URL found');
    }

    return url;
  } catch (error) {
    console.error('Error happened while uploading logo: ', error);
    throw error;
  }
};
