import { lazy } from 'react';
import ChannelsTab from './components/common/icons/ChannelsTab';
import Briefcase from './components/common/icons/Briefcase';
import BundlesTab from './components/common/icons/BundlesTab';
import DashboardIcon from './components/common/icons/DashboardIcon';
import SettingsIcon from './components/common/icons/SettingsIcon';
const Dashboard = lazy(() => import('@/pages/Dashboard'));
const Jobs = lazy(() => import('@/pages/Jobs'));
const Channels = lazy(() => import('@/pages/Channels'));
const Bundles = lazy(() => import('@/pages/Bundles'));
const CreateJob = lazy(() => import('@/pages/CreateJob'));
const Publish = lazy(() => import('@/pages/PublishChannels'));
const Settings = lazy(() => import('@/pages/SettingsPage'));
const Success = lazy(() => import('@/pages/Success'));
const Failure = lazy(() => import('@/pages/Failure'));
const Error404 = lazy(() => import('@/pages/404'));
const Error500 = lazy(() => import('@/pages/500'));

const routing = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: <DashboardIcon />,
    element: <Dashboard />,
  },
  {
    path: '/jobs',
    name: 'Jobs',
    icon: <Briefcase />,
    element: <Jobs />,
  },
  {
    path: '/jobs/create',
    name: 'Create',
    element: <CreateJob />,
  },
  {
    path: '/jobs/:jobId/edit',
    name: 'Edit',
    element: <CreateJob />,
  },
  {
    path: '/channels',
    name: 'Channels',
    icon: <ChannelsTab />,
    element: <Channels />,
  },
  {
    path: '/bundles',
    name: 'Bundles',
    icon: <BundlesTab />,
    element: <Bundles />,
  },
  {
    path: '/settings',
    name: 'Settings',
    icon: <SettingsIcon />,
    element: <Settings />,
  },
  {
    path: '/jobs/:jobId/publish',
    name: 'Publish',
    element: <Publish />,
  },
  {
    path: '/orders/success',
    name: 'Success',
    element: <Success />,
    notInMenu: true,
  },
  {
    path: '/orders/cansel',
    name: 'Failure',
    element: <Failure />,
    notInMenu: true,
  },
  {
    path: '/404',
    name: 'Error404',
    element: <Error404 />,
    notInMenu: true,
  },
  {
    path: '/500',
    name: 'Error500',
    element: <Error500 />,
    notInMenu: true,
  },
];

export default routing;
