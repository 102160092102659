import { ICartItem } from "@/utils/types";

const CART = 'cart';

export const getCartItems = (): ICartItem[] => {
  const data = window.localStorage.getItem(CART);

  return data ? JSON.parse(data) : [];
}

export const addCartItem = (item: ICartItem) => {
  const existing = getCartItems();

  window.localStorage.setItem(CART, JSON.stringify([...existing, item]));
};

export const removeCartItem = (index: number) => {
  const existing = getCartItems();
  existing.splice(index, 1);

  window.localStorage.setItem(CART, JSON.stringify(existing));
}

export const clearCart = () => {
  window.localStorage.setItem(CART, JSON.stringify([]));
};
