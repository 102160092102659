import { auth } from '@/services/firebase';
import { LoaderContext } from '@/context';
import { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from '../common/Button';
import { Wrapper, MobileWrapper } from './index.styles';

interface Props {
  closeMobileNav?: () => void;
  isMobile?: boolean;
  photoUrl: string | null;
}

const Account = ({ closeMobileNav, isMobile, photoUrl }: Props) => {
  const { t } = useTranslation();
  const { startLoader, stopLoader } = useContext(LoaderContext);
  const navigate = useNavigate();

  const logOut = () => {
    startLoader();
    auth.signOut().finally(() => stopLoader());
    if (closeMobileNav) {
      closeMobileNav();
    }
  };

  return !isMobile ? (
    <Wrapper>
      <Button size="small" variant="outlined" onClick={() => navigate('/dashboard')}>
        Dashboard
      </Button>
      <Button size="small" variant="contained" onClick={logOut}>
        {t('common.buttons.logOut')}
      </Button>
    </Wrapper>
  ) : (
    <MobileWrapper>
      <Button size="small" variant="outlined" onClick={() => navigate('/dashboard')}>
        Dashboard
      </Button>
      <Button size="small" variant="contained" onClick={logOut}>
        {t('common.buttons.logOut')}
      </Button>
    </MobileWrapper>
  );
};

export default memo(Account);
