import { memo } from 'react';

interface Props {
  onClick?: (e: React.SyntheticEvent) => void;
}

const InfoCircle = ({ onClick }: Props) => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 28.24609375 28.24609375"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
  >
    <path
      fill="grey"
      d="M14.1227 28.2441C21.8427 28.2441 28.2427 21.8641 28.2427 14.1241C28.2427 6.3941 21.8427 0.0041 14.1127 0.0041C6.3827 0.0041 0.0027 6.3941 0.0027 14.1241C0.0027 21.8641 6.4027 28.2441 14.1227 28.2441ZM14.1227 25.4741C7.8327 25.4741 2.7927 20.4141 2.7927 14.1241C2.7927 7.8341 7.8227 2.7841 14.1127 2.7841C20.4027 2.7841 25.4727 7.8341 25.4727 14.1241C25.4727 20.4141 20.4127 25.4741 14.1227 25.4741ZM14.0427 9.6341C15.0627 9.6341 15.8827 8.8041 15.8827 7.7941C15.8827 6.7541 15.0627 5.9341 14.0427 5.9341C13.0127 5.9341 12.1927 6.7541 12.1927 7.7941C12.1927 8.8041 13.0127 9.6341 14.0427 9.6341ZM11.7327 21.6441H17.2427C17.8427 21.6441 18.3227 21.2041 18.3227 20.5941C18.3227 20.0141 17.8427 19.5641 17.2427 19.5641H15.7127V13.0941C15.7127 12.2941 15.3127 11.7541 14.5427 11.7541H11.9727C11.3627 11.7541 10.8927 12.2241 10.8927 12.7941C10.8927 13.4141 11.3627 13.8541 11.9727 13.8541H13.3527V19.5641H11.7327C11.1327 19.5641 10.6527 20.0141 10.6527 20.5941C10.6527 21.2041 11.1327 21.6441 11.7327 21.6441Z"
      fillRule="evenodd"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default memo(InfoCircle);
