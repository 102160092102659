import { UserContext } from '@/context';
import useOnClickOutside from '@/hooks/useOnClickOutside';
import { findDevelopersLinks } from '@/utils/constants';
import { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import Button from '../common/Button';
import DropdownArrow from '../common/icons/DropdownArrow';
import Account from './Account';
import * as styles from './index.styles';

interface Props {
  closeMobileNav?: () => void;
  isMobile?: boolean;
}

const NavLinks = ({ closeMobileNav, isMobile }: Props) => {
  const { user } = useContext(UserContext);
  const { pathname } = useLocation();
  const isHomePage = pathname === '/';
  const showHomeButton = !isHomePage;
  const showAccountButton =
    pathname !== '/terms-of-service' &&
    pathname !== '/privacy-policy' &&
    pathname !== '/cookie-policy' &&
    pathname !== '/find-developers-in-reddit' &&
    pathname !== '/find-developers-in-telegram' &&
    pathname !== '/find-developers-in-job-board' &&
    pathname !== '/find-developers-bundles' &&
    pathname !== '/find-developers-discover' &&
    pathname !== '/unauthorised-channels' &&
    pathname !== '/unauthorised-bundles';
  const isLoginPage = pathname === '/login';
  const { t } = useTranslation();
  const wrapperRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const [isOpen, setIsOpen] = useState(false);

  useOnClickOutside(wrapperRef, isOpen, () => {
    setIsOpen(false);
  });

  const triggerClose = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <nav>
        <div>
          <HashLink to="/#pricing" onClick={closeMobileNav}>
            <Button size="small" weight="normal">
              {t('common.buttons.pricing')}
            </Button>
          </HashLink>
          {!isMobile ? (
            <styles.Dropdown
              ref={wrapperRef}
              isOpen={isOpen}
              onClick={triggerClose}
            >
              <Button
                size="small"
                weight="normal"
                secondIcon={<DropdownArrow />}
              >
                {t('common.buttons.findDevelopers')}
              </Button>
              <styles.Content isOpen={isOpen}>
                {findDevelopersLinks.map((item) => (
                  <Link key={item.url} to={item.url}>
                    {t(`${item.text}`)}
                  </Link>
                ))}
              </styles.Content>
            </styles.Dropdown>
          ) : (
            <div>
              {findDevelopersLinks.map((item) => (
                <Link key={item.url} to={item.url} onClick={closeMobileNav}>
                  <Button size="small" weight="normal">
                    {t(`${item.text}`)}
                  </Button>
                </Link>
              ))}
            </div>
          )}
        </div>
        <div>
          {user ? (
            showAccountButton && (
              <Account
                closeMobileNav={closeMobileNav}
                isMobile={isMobile}
                photoUrl={user?.photoURL}
              />
            )
          ) : (
            <>
              {!isLoginPage && (
                <Link to="/login" onClick={closeMobileNav}>
                  <Button size="small" weight="normal">
                    <styles.Span>{t('common.buttons.login')}</styles.Span>
                  </Button>
                </Link>
              )}
              {isHomePage && (
                <Link to="/signup" onClick={closeMobileNav}>
                  <Button variant="outlined" size="small">
                    {t('common.buttons.try')}
                  </Button>
                </Link>
              )}
            </>
          )}
          {showHomeButton && (
            <HashLink to="/" onClick={closeMobileNav}>
              <Button variant="outlined" size="small">
                {t('common.buttons.home')}
              </Button>
            </HashLink>
          )}
        </div>
      </nav>
    </>
  );
};

export default NavLinks;
