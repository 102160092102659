import { memo } from 'react';

interface Props {
  onClick?: (e: React.SyntheticEvent) => void;
}

const SettingsIcon = ({ onClick }: Props) => (
  <svg
    width="31"
    height="31"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
  >
    <path
      d="M10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.61929 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 8.61929 12.5 10 12.5Z"
      stroke="#696C83"
      strokeWidth="1.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.66669 10.7333V9.26666C1.66669 8.4 2.37502 7.68333 3.25002 7.68333C4.75835 7.68333 5.37502 6.61666 4.61669 5.30833C4.18335 4.55833 4.44169 3.58333 5.20002 3.15L6.64169 2.325C7.30002 1.93333 8.15002 2.16666 8.54169 2.825L8.63335 2.98333C9.38335 4.29166 10.6167 4.29166 11.375 2.98333L11.4667 2.825C11.8584 2.16666 12.7084 1.93333 13.3667 2.325L14.8084 3.15C15.5667 3.58333 15.825 4.55833 15.3917 5.30833C14.6334 6.61666 15.25 7.68333 16.7584 7.68333C17.625 7.68333 18.3417 8.39166 18.3417 9.26666V10.7333C18.3417 11.6 17.6334 12.3167 16.7584 12.3167C15.25 12.3167 14.6334 13.3833 15.3917 14.6917C15.825 15.45 15.5667 16.4167 14.8084 16.85L13.3667 17.675C12.7084 18.0667 11.8584 17.8333 11.4667 17.175L11.375 17.0167C10.625 15.7083 9.39169 15.7083 8.63335 17.0167L8.54169 17.175C8.15002 17.8333 7.30002 18.0667 6.64169 17.675L5.20002 16.85C4.44169 16.4167 4.18335 15.4417 4.61669 14.6917C5.37502 13.3833 4.75835 12.3167 3.25002 12.3167C2.37502 12.3167 1.66669 11.6 1.66669 10.7333Z"
      stroke="#696C83"
      strokeWidth="1.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default memo(SettingsIcon);
