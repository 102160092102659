import { ThemeProps } from '@/styles/globalStyles';
import styled from 'styled-components';

export const Layout = styled.div`
  height: 100vh;
  box-sizing: border-box;
  overflow-y: auto;
  background-color: ${(props: ThemeProps) => props.theme.secondaryBackground};
  margin: 0 auto;
  width: 100vw;
`;

export const ContentWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
  @media (max-width: 48rem) {
    padding: 1.25rem 1.25rem;
  }
`;

export const NameOfPageBlock = styled.div`
  text-align: left;
  font-weight: 600;
  font-size: 1.75rem;
  color: rgb(86, 86, 86);
  margin: 1rem 0 2rem 0;
  @media (max-width: 48rem) {
    display: none;
  }
`;

export const Wrapper = styled.div`
  max-width: 100%;
  width: 100%;
  margin: 0 2rem 0 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  @media (max-width: 48rem) {
    margin: 0;
  }
  &::-webkit-scrollbar {
    width: 0.188rem;
    height: 0.063rem;
  }
`;

export const Content = styled.div`
  width: 100%;
  margin-top: 1rem;
  box-sizing: border-box;
`;
