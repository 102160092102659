import styled from 'styled-components';
import { ThemeProps } from '@/styles/globalStyles';

interface ILinks {
  isOpen: boolean;
}
export const Content = styled.header`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;
  padding: 0.75rem 1.5rem;
  max-height: 4rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  box-shadow: rgb(60 72 88 / 15%) 0px 0px 3px;
  background-color: ${(props: ThemeProps) => props.theme.white};
  @media (max-width: 48rem) {
    padding: 0.75rem;
    margin: 1rem;
    border-radius: 0.75rem;
    max-height: 3.5rem;
  }
`;

export const Background = styled.div`
  display: ${(props: ILinks) => (props.isOpen ? `block` : `none`)};
  position: fixed;
  z-index: 3;
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${(props: ThemeProps) => props.theme.black}90;
`;

export const NameOfPageBlock = styled.div`
  display: none;
  text-align: left;
  font-weight: 600;
  font-size: 1.2rem;
  color: rgb(86, 86, 86);
  margin: 0.5rem 0;
  @media (max-width: 48rem) {
    display: block;
  }
`;

export const SecondContent = styled.div`
  padding: 1.5rem 1rem 0 1rem;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  box-sizing: border-box;
`;

export const ButtonBlock = styled.div`
  margin-right: 2rem;
  & button {
    max-height: 2.5rem;
  }
  @media (max-width: 48rem) {
    & button {
      max-height: 2.3rem;
    }
  }
`;

export const LogoBlock = styled.div`
  position: absolute;
  left: 1.75rem;
`;

export const MobileHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

export const LinksWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem 1rem 1.5rem;
  background-color: ${(props: ThemeProps) => props.theme.white};
  top: 3.6rem;
  right: 0;
  transition: opacity 0.2s ease-out;
  box-sizing: border-box;
  z-index: 3;
  border-radius: 0.75rem;
`;

export const Navigation = styled.nav`
  display: flex;
  flex-direction: column;
  & > a {
    margin-bottom: 0.5rem;
  }
`;

export const Separator = styled.div`
  width: 100%;
  height: 0;
  border: 0.063rem solid ${(props: ThemeProps) => props.theme.stroke};
  margin-bottom: 1.25rem;
`;

export const CartBlock = styled.div`
  max-width: 15rem;
  width: 100%;
  margin-right: 1rem;
  @media (max-width: 48rem) {
    margin: 1rem 0;
    max-width: 100%;
  }
`;
export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;