import styled, { css } from 'styled-components';
import { ThemeProps } from '@/styles/globalStyles';

interface Props {
  children: any;
  variant: 'text' | 'contained' | 'outlined' | 'tab';
  size: 'medium' | 'small';
  weight: 'bold' | 'normal';
  routing: string | undefined;
}


export const CustomButton = styled.button`
  border-radius: 0.75rem;
  padding: ${(props: Props) =>
    props.size === 'small' ? '0.75rem 1.5rem' : '1rem 3rem'};
  max-height: ${(props: Props) => (props.size === 'small' ? '3rem' : 'none')};
  font-size: ${(props: Props) =>
    props.size === 'small' ? '1rem' : '1.125rem'};
  font-weight: ${(props: Props) => (props.weight === 'normal' ? 600 : 700)};
  line-height: ${(props: Props) =>
    props.size === 'small' ? '1.5rem' : '1.688rem'};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  ${(props: Props) =>
    props.variant === 'contained'
      ? css`
          background-color: ${(props: ThemeProps) => props.theme.blue};
          color: ${(props: ThemeProps) => props.theme.white};
          @media (max-width: 48rem) {
            padding: 0.75rem 2rem;
          }
        `
      : props.variant === 'outlined'
      ? css`
          color: ${(props: ThemeProps) => props.theme.blue};
          border: 0.063rem solid ${(props: ThemeProps) => props.theme.blue};
          background-color: transparent;
          @media (max-width: 48rem) {
            padding: 0.75rem 2rem;
          }
        `
      : props.variant === 'tab'
      ? css`
          color: ${(props: ThemeProps) => props.theme.blue};
          background-color: ${(props: ThemeProps) =>
            props.theme.secondaryBackground};
          & svg path[fill] {
            fill: ${(props: ThemeProps & Props) =>  props.theme.blue};
          }
          & svg path[stroke] {
            stroke: ${(props: ThemeProps) => props.theme.blue};
          }
          @media (max-width: 48rem) {
            padding: 0.75rem 2rem;
          }
        `
      : css`
          color: ${(props: ThemeProps) => props.theme.deepDarkBlue};
          background-color: transparent;
          padding-left: 0;
          padding-right: 0;
          @media (max-width: 48rem) {
            padding: 0.75rem 0;
          }
        `}
  &:hover {
    transform: scale(1.05);
  }
  &:disabled {
    pointer-events: none;
    opacity: 0.7;
  }
  & span {
    white-space: nowrap;
  }
  & svg + span {
    margin-left: 0.75rem;
  }
  & svg {
    max-height: ${(props: Props) =>
      props.size === 'small' ? '1.2rem' : '1.688rem'};
  }
  @media (max-width: 48rem) {
    font-size: 1rem;
    line-height: 1.5rem;
    & svg {
      max-height: ${(props: Props) =>
        props.size === 'small' ? '1.2rem' : '1.5rem'};
    }
  }
`;
