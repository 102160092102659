import { ThemeProps } from '@/styles/globalStyles';
import styled from 'styled-components';

export const Content = styled.aside`
  position: sticky;
  z-index: 99;
  top: 4rem;
  left: 0;
  background-color: ${(props: ThemeProps) => props.theme.white};
  min-width: 5.625rem;
  box-shadow: rgb(60 72 88 / 15%) 0px 0px 3px;
  max-width: 5.625rem;
  height: calc(100vh - 4rem);
  padding: 2rem 0.5rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media (max-width: 48rem) {
    display: none;
  }
`;

export const HoverBlock = styled.div`
  position: absolute;
  top: 20%;
  left: 5.8rem;
  z-index: 100;
  display: none;
`;

export const Triangle = styled.div`
  position: absolute;
  left: -0.5rem;
  top: 0.1563rem;
  background-color: ${(props: ThemeProps) => props.theme.black};
  clip-path: polygon(0 48%, 100% 100%, 100% 0);
  width: 1rem;
  border-radius: 0.625rem;
  height: 2rem;
`;

export const TabName = styled.div`
  position: relative;
  padding: 0.6rem 1rem;
  color: ${(props: ThemeProps) => props.theme.white};
  border-radius: 0.625rem;
  background-color: ${(props: ThemeProps) => props.theme.black};
`;

export const Navigation = styled.nav`
  & a {
    display: inline-block;
    position: relative;
    &:hover ${HoverBlock} {
      display: block;
    }
    & button {
      max-width: 100%;
      justify-content: flex-start;
      padding: 1rem 1.3rem;
    }
  }
`;
