import { memo } from 'react';

interface Props {
  onClick?: (e: React.SyntheticEvent) => void;
}

const Briefcase = ({ onClick }: Props) => (
  <svg
    width="31"
    height="27"
    viewBox="0 0 31.03515625 27.1796875"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
  >
    <path
      fill="#696C83"
      d="M4.4137 27.1828H26.6037C29.5437 27.1828 31.0337 25.7028 31.0337 22.8028V9.4228C31.0337 6.5228 29.5437 5.0428 26.6037 5.0428H22.6837V3.8028C22.6837 1.2128 21.2737 0.0028 18.7037 0.0028H12.3137C9.8537 0.0028 8.3537 1.2128 8.3537 3.8028V5.0428H4.4137C1.5037 5.0428 0.0037 6.5228 0.0037 9.4228V22.8028C0.0037 25.7028 1.5037 27.1828 4.4137 27.1828ZM10.8437 3.8028C10.8437 2.8328 11.4037 2.2828 12.4037 2.2828H18.6237C19.6337 2.2828 20.1937 2.8328 20.1937 3.8028V5.0428H10.8437ZM2.6837 9.5128C2.6837 8.2528 3.3237 7.6428 4.5237 7.6428H26.5137C27.7137 7.6428 28.3537 8.2528 28.3537 9.5128V11.2828C24.5637 12.5828 20.2237 13.2328 15.5137 13.2328C10.8137 13.2328 6.4637 12.5828 2.6837 11.2828ZM4.5237 24.5928C3.3237 24.5928 2.6837 23.9628 2.6837 22.7228V13.8928C5.4937 14.7428 8.5437 15.2828 11.7337 15.5328V16.6128C11.7337 17.6328 12.3337 18.2128 13.3837 18.2128H17.6537C18.7037 18.2128 19.2937 17.6328 19.2937 16.6128V15.5328C22.4937 15.2828 25.5437 14.7428 28.3537 13.8928V22.7228C28.3537 23.9628 27.7137 24.5928 26.5137 24.5928Z"
      fillRule="evenodd"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default memo(Briefcase);
