import { ISelectJob } from '@/utils/types';
import { useMemo, useRef, useState } from 'react';
import Button from '@/components/common/Button';
import DropdownArrow from '@/components/common/icons/DropdownArrow';
import { Select, Element } from '@/components/Select/index.styles';
import * as styles from './index.styles';
import useOnClickOutside from '@/hooks/useOnClickOutside';
import Close from '@/components/common/icons/Close';
import { useNavigate } from 'react-router-dom';

interface IProps {
  options: ISelectJob[];
  onChange: (event: any) => void;
  disabled?: boolean;
  value: string;
  setCloseCart: () => void;
}

const JobsSelect = ({
  setCloseCart,
  options,
  onChange,
  value,
  disabled,
}: IProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  const triggerClose = () => {
    setIsOpen((prevState) => !prevState);
  };
  const navigate = useNavigate();

  const handleChange = (option: any) => {
    if (onChange) {
      onChange(option);
    }
    setIsOpen(false);
  };

  const buttonTitle: string = useMemo(() => {
    if (value) {
      return options.find((option) => option.value === value)?.title || '';
    }

    return 'Choose a job';
  }, [value]);

  useOnClickOutside(wrapperRef, isOpen, () => {
    setIsOpen(false);
  });

  return (
    <styles.SelectBlock ref={wrapperRef}>
      <Select isOpen={isOpen} onClick={triggerClose} disabled={disabled}>
        <styles.SelectButtonWrapper>
          <Button size="small" weight="normal">
            {buttonTitle}
          </Button>
          <DropdownArrow />
        </styles.SelectButtonWrapper>
        <styles.ContentWrapper isOpen={isOpen}>
          <styles.HeaderContent onClick={(e) => e.stopPropagation()}>
            <styles.HeaderTitle>Your jobs:</styles.HeaderTitle>
            <styles.IconBlock onClick={() => setIsOpen(false)}>
              <Close />
            </styles.IconBlock>
          </styles.HeaderContent>
          <styles.Content isEmpty={!options.length}>
            {options.length ? (
              options.map((option) => (
                <Element
                  key={JSON.stringify(option)}
                  onClick={() => {
                    handleChange(option.value);
                    triggerClose();
                  }}
                >
                  <styles.ElemetnContent>
                    <img
                      loading="lazy"
                      src={
                        option.companyLogo
                          ? option.companyLogo
                          : `/images/default_job_placeholder.png`
                      }
                      alt=""
                    />
                    <styles.ElementInfo>
                      <div>{option.title}</div>
                      <span>{option.company}</span>
                    </styles.ElementInfo>
                  </styles.ElemetnContent>
                </Element>
              ))
            ) : (
              <>
                <styles.EmptyBlock>
                  There are no currently jobs yet :(
                </styles.EmptyBlock>
                <styles.FooterContent
                  onClick={() => {
                    setCloseCart();
                    navigate('/jobs/create');
                  }}
                >
                  <styles.Plus>+</styles.Plus>
                  <div>Create a new job</div>
                </styles.FooterContent>
              </>
            )}
          </styles.Content>
        </styles.ContentWrapper>
      </Select>
    </styles.SelectBlock>
  );
};

export default JobsSelect;
