import { ThemeProps } from '@/styles/globalStyles';
import styled from 'styled-components';


interface ArrowProps {
  isOpen: boolean;
}

export const ArrowWrapper = styled.i`
  display: flex;
  margin-left: 0.6rem;
  & svg {
    transform: ${(props: ArrowProps) =>
      props.isOpen ? 'rotate(180deg)' : 'none'};
  }
`;

export const AccountButton = styled.button`
  position: relative;
  padding: 0.25rem 0;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  & img {
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
  }
  & svg {
    margin-left: 0.5rem;
    &:hover {
      transform: scale(1.2);
    }
  }
  & span {
    color: ${(props: ThemeProps) => props.theme.deepDarkBlue};
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-left: 0.75rem;
    margin-right: 1rem;
    @media (max-width: 69rem) {
      display: none;
    }
    @media (max-width: 48rem) {
      display: block;
    }
  }
`;

export const AccountActions = styled.div`
  position: absolute;
  top: calc(100% - 0.2rem);
  right: 0;
  width: 9.25rem;
  padding: 0.5rem 0;
  border-radius: 0.75rem;
  background-color: ${(props: ThemeProps) => props.theme.white};
  box-shadow: -1.65999px 2.48998px 8.29993px rgba(55, 58, 79, 0.08);
  & a {
    padding: 0.5rem 1.625rem;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.375rem;
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    text-align: left;
    color: ${(props: ThemeProps) => props.theme.deepDarkBlue};
    & svg {
      margin-right: 0.75rem;
    }
    &:hover {
      transform: scale(1.05);
    }
    &:last-child {
      color: ${(props: ThemeProps) => props.theme.blue};
    }
  }
`;
