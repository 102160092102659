import styled from 'styled-components';
import { ThemeProps } from '@/styles/globalStyles';
import { StylesProps } from '@/utils/stylesProps';

type Props = StylesProps & ThemeProps;

interface IProps {
  backgroundColor?: string;
  textColor?: string;
  positionTop?: string;
  mobilePositionTop?: string;
  paddingButton?: string;
  buttonSize?: string;
  width?: number;
}

export const TooltipContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

export const TooltipContent = styled.div`
  display: ${(props: Props) => (props.isOpen ? `inline` : `none`)};
  box-shadow: 0px 3px 25px rgba(55, 58, 79, 0.03),
    7px 6px 22px rgba(55, 58, 79, 0.1);
  background-color: ${(props: IProps & Props) =>
    props.backgroundColor ? props.backgroundColor : props.theme.black};
  border-radius: 0.625rem;
  padding: 0.5rem;
  width: ${(props: IProps) => (props.width ? `${props.width}px` : `100%`)};
  box-sizing: border-box;
  font-weight: 400;
  font-size: 1rem;
  text-align: center;
  color: ${(props: IProps & Props) =>
    props.textColor ? props.textColor : props.theme.white};
  position: absolute;
  z-index: 9999;
  top: ${(props: IProps & Props) =>
    props.positionTop ? props.positionTop : `2.5rem`};
  left: ${(props: IProps) =>
    props.width ? `calc((${props.width / 2}px - 1rem) * -1)` : 0};
  @media (max-width: 48rem) {
    top: ${(props: IProps & Props) =>
      props.mobilePositionTop ? props.mobilePositionTop : `2.5rem`};
    font-size: 0.85rem;
  }
`;

export const TooltipContentTriangle = styled.div`
  position: relative;
  width: 100%;
  z-index: 3;
`;

export const BottomArrow = styled.div`
  position: absolute;
  bottom: -1.3rem;
  background-color: ${(props: ThemeProps) => props.theme.black};
  clip-path: polygon(0 48%, 100% 100%, 100% 0);
  transform: rotate(-90deg) translateY(-50%);
  left: 50%;
  right: 50%;
  width: 1rem;
  border-radius: 0.625rem;
  height: 1rem;
`;

export const TooltipButton = styled.div`
  padding: ${(props: IProps & Props) =>
    props.paddingButton ? props.paddingButton : `0 0 0 0.313rem`};
  & svg {
    height: 0.8rem;
    width: 0.8rem;
  }
`;
