import styled from 'styled-components';
import { ThemeProps } from '@/styles/globalStyles';
import { StylesProps } from '@/utils/stylesProps';

type Props = ThemeProps & StylesProps;

export const DropdownContainer = styled.div`
  width: 100%;
  height: 2.75rem;
  @media (max-width: 89.375rem) {
    max-width: 100%;
  }
  @media (max-width: 65rem) {
    margin-right: 0rem;
  }
`;

export const DropdownButton = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  box-sizing: border-box;
  padding: 0.625rem 1rem;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.white};
  border-radius: 0.5rem;
  cursor: pointer;
  font-weight: 600;
  font-size: 1rem;
  color: ${(props: Props) => props.theme.deepDarkBlue};
  line-height: 1.5rem;
  border: 0.0625rem solid
    ${(props: Props) => (props.isOpen ? props.theme.blue : props.theme.stroke)};
  span {
    margin: 0 0 0 0.375rem;
    color: ${(props: Props) => props.theme.blue};
  }
  & svg {
    margin-right: 0.875rem;
  }
  @media (max-width: 48rem) {
    line-height: 1.3125rem;
    padding-left: 0.75rem;
    padding-right: 0;
    span {
      padding: 0.1875rem;
    }
    & svg {
      margin-right: 0.375rem;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CloseButton = styled.div`
  cursor: pointer;
`;

export const Title = styled.div`
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.6875rem;
  span {
    margin: 0 0 0 0.375rem;
    color: ${(props: Props) => props.theme.blue};
  }
  @media (max-width: 48rem) {
    font-size: 1rem;
  }
`;
export const DropdownMenu = styled.ul`
  position: relative;
  z-index: 3;
  box-sizing: border-box;
  display: ${(props: Props) => (props.isOpen ? `flex` : `none`)};
  flex-direction: column;
  margin-top: 0.25rem;
  padding: 1.5rem;
  justify-content: space-around;
  list-style: none;
  background-color: ${(props: Props) => props.theme.white};
  color: ${(props: ThemeProps) => props.theme.black};
  width: 150%;
  left: -50%;
  box-shadow: 0px 3px 25px rgba(55, 58, 79, 0.03),
    7px 6px 22px rgba(55, 58, 79, 0.1);
  border-radius: 0.5rem;
  font-weight: 300;
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.2rem;
    &:last-child {
      margin-bottom: 0;
    }
    & button {
      padding: 0;
      text-align: start;
    }
  }
  @media (max-width: 48rem) {
    max-width: 100%;
    left: 0;
    right: 0;
  }
  @media (max-width: 48rem) {
    margin-top: 0.5rem;
    padding: 1rem;
    li {
      margin: 0.5rem 0;
    }
  }
`;

export const PriceBlock = styled.div`
  margin-bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
  color: ${(props: Props) => props.theme.grey};
  span {
    font-size: 1.125rem;
    font-weight: 700;
    color: ${(props: Props) => props.theme.blue};
  }
`;

export const Separator = styled.div`
  height: 1px;
  margin: 1.5rem 0;
  background: ${(props: ThemeProps) => props.theme.stroke};
`;

export const InfoBlock = styled.div`
  display: flex;
  & img {
    max-width: 1.5rem;
    max-height: 1.5rem;
    width: 100%;
    height: 100;
    object-fit: contain;
    margin-right: 0.5rem;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Name = styled.div`
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.25rem;
`;

export const NumberOfDays = styled.div`
  display: flex;
  margin-top: 0.125rem;
  font-size: 0.75rem;
  align-items: center;
  color: ${(props: ThemeProps) => props.theme.grey};
`;

export const RoundSeparator = styled.div`
  width: 0.25rem;
  height: 0.25rem;
  background-color: ${(props: ThemeProps) => props.theme.stroke};
  margin: 0 0.5rem;
  border-radius: 0.2rem;
`;

export const ProductPriceBlock = styled.div`
  display: flex;
  margin-left: 5px;
  align-items: center;
`;

export const ProductPrice = styled.div`
  font-size: 0.875rem;
  font-weight: 700;
`;

export const TrashIconWrapper = styled.div`
  margin-left: 0.5rem;
  cursor: pointer;
`;

export const ButtonWrapper = styled.div`
  position: relative;
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
  width: 100%;
  & button {
    width: 100%;
    max-height: 3rem;
  }
`;

export const JobName = styled.div`
  margin: 1rem 0;
  line-height: 1.2rem;
  font-weight: 500;
  span {
    font-weight: 700;
    word-wrap: break-word;
    color: ${(props: ThemeProps) => props.theme.blue};
  }
`;

export const CompanyName = styled.div`
  color: ${(props: ThemeProps) => props.theme.blue};
  margin: 0.5rem 1rem;
`;

export const SelectWrapper = styled.div`
  width: 100%;
  max-height: auto;
`;

export const CartIsEmpty = styled.div`
  display: flex;
  justify-content: center;
  color: ${(props: ThemeProps) => props.theme.grey};
`;

export const CartItemsBlock = styled.div`
  max-height: 19rem;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0.2rem;
    height: 0.5rem;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: ${(props: ThemeProps) => props.theme.stroke};
  }
`;
