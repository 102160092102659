import { memo } from 'react';

interface Props {
  onClick?: (e: React.SyntheticEvent) => void;
}

const DashboardIcon = ({ onClick }: Props) => (
  <svg
    width="31"
    height="27"
    viewBox="0 0 33.373046875 29.216796875"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
  >
    <path
      fill="#696C83"
      d="M7.3937 29.2211H25.8937C27.9737 29.2211 29.1937 28.0311 29.1937 26.0011V13.1911L30.8537 14.5911C31.1837 14.8611 31.5537 15.0911 31.9737 15.0911C32.7737 15.0911 33.3737 14.6011 33.3737 13.8211C33.3737 13.3611 33.1937 12.9911 32.8437 12.7011L29.1937 9.6211V3.8111C29.1937 3.2011 28.7937 2.8011 28.1737 2.8011H26.3337C25.7337 2.8011 25.3237 3.2011 25.3237 3.8111V6.3711L18.6237 0.7411C17.4437 -0.2489 15.9737 -0.2489 14.7837 0.7411L0.5337 12.7011C0.1737 13.0011 0.0037 13.4011 0.0037 13.7811C0.0037 14.4911 0.5337 15.0911 1.4037 15.0911C1.8337 15.0911 2.2037 14.8611 2.5337 14.5911L4.1037 13.2711V26.0011C4.1037 28.0311 5.3137 29.2211 7.3937 29.2211ZM20.6137 17.5711C20.6137 16.9211 20.2037 16.5111 19.5637 16.5111H13.8037C13.1837 16.5111 12.7437 16.9211 12.7437 17.5711V26.5411H8.1037C7.2437 26.5411 6.7837 26.0611 6.7837 25.2011V11.0211L16.1237 3.2011C16.4837 2.8711 16.9137 2.8711 17.2837 3.2011L26.5137 10.9411V25.2011C26.5137 26.0611 26.0437 26.5411 25.1837 26.5411H20.6137Z"
      fillRule="evenodd"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default memo(DashboardIcon);
