import { memo } from 'react';

interface Props {
  onClick?: (e: React.SyntheticEvent) => void;
}

const ChannelsTab = ({ onClick }: Props) => (
  <svg
  width="31"
  height="27"
    viewBox="0 0 29.61328125 29.298828125"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
  >
    <path
      fill="#696C83"
      d="M4.4837 22.8H6.8037V24.87C6.8037 27.78 8.3237 29.3 11.2937 29.3H25.1337C28.0837 29.3 29.6137 27.78 29.6137 24.87V10.92C29.6137 8.01 28.0837 6.49 25.1337 6.49H22.8037V4.43C22.8037 1.52 21.2737 0 18.3237 0H4.4837C1.5337 0 0.0037 1.5 0.0037 4.43V18.36C0.0037 21.29 1.5337 22.8 4.4837 22.8ZM4.6437 20.12C3.3737 20.12 2.6837 19.47 2.6837 18.14V4.66C2.6837 3.34 3.3737 2.67 4.6437 2.67H18.1737C19.4237 2.67 20.1437 3.34 20.1437 4.66V6.49H11.2937C8.3237 6.49 6.8037 8.01 6.8037 10.92V20.12ZM11.4437 26.62C10.1737 26.62 9.4837 25.96 9.4837 24.64V11.16C9.4837 9.83 10.1737 9.17 11.4437 9.17H24.9637C26.2237 9.17 26.9337 9.83 26.9337 11.16V24.64C26.9337 25.96 26.2237 26.62 24.9637 26.62Z"
      fillRule="evenodd"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default memo(ChannelsTab);
