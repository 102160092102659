import { memo } from 'react';

interface Props {
  onClick?: (e: React.SyntheticEvent) => void;
}

const BundlesTab = ({ onClick }: Props) => (
  <svg
    width="31"
    height="27"
    viewBox="0 0 26.71484375 29.0390625"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
  >
    <path
      fill="#696C83"
      d="M5.9237 29.0352H20.7837C23.3637 29.0352 24.8137 27.6452 24.8137 25.0652V16.8752H24.8937C26.0837 16.5452 26.7137 15.5152 26.7137 14.0452V10.5652C26.7137 8.7252 25.6937 7.5752 23.8437 7.5752H21.4537C22.1937 6.8252 22.6237 5.8252 22.6237 4.6652C22.6237 1.9552 20.4537 -0.0048 17.7437 -0.0048C15.7237 -0.0048 14.0237 1.1352 13.3537 3.1352C12.6837 1.1352 10.9737 -0.0048 8.9737 -0.0048C6.2437 -0.0048 4.0837 1.9552 4.0837 4.6652C4.0837 5.8252 4.5137 6.8252 5.2537 7.5752H2.8737C1.0937 7.5752 0.0037 8.7252 0.0037 10.5652V14.0452C0.0037 15.5152 0.6337 16.5452 1.8137 16.8752H1.9037V25.0652C1.9037 27.6452 3.3337 29.0352 5.9237 29.0352ZM12.0537 7.5752H10.0037C7.9037 7.5752 6.7037 6.4152 6.7037 4.9252C6.7037 3.4552 7.7237 2.5752 9.2037 2.5752C10.7837 2.5752 12.0537 3.8252 12.0537 5.7552ZM14.6437 7.5752V5.7552C14.6437 3.8252 15.9237 2.5752 17.5137 2.5752C18.9937 2.5752 20.0137 3.4552 20.0137 4.9252C20.0137 6.4152 18.8037 7.5752 16.7037 7.5752ZM11.9737 14.6152H3.5837C2.9237 14.6152 2.6537 14.3452 2.6537 13.6852V10.9252C2.6537 10.2652 2.9237 9.9952 3.5837 9.9952H11.9737ZM14.7237 14.6152V9.9952H23.1437C23.8037 9.9952 24.0637 10.2652 24.0637 10.9252V13.7152C24.0637 14.3552 23.8037 14.6152 23.1437 14.6152ZM11.9737 26.5952H6.2337C5.1537 26.5952 4.5537 25.9752 4.5537 24.9052V17.0352H11.9737ZM14.7237 26.5952V17.0352H22.1637V24.9052C22.1637 25.9752 21.5437 26.5952 20.4637 26.5952Z"
      fillRule="evenodd"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default memo(BundlesTab);
